import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import React, { useState, useEffect, useRef } from 'react';
import { FaArrowAltCircleRight, FaArrowAltCircleLeft, FaPauseCircle, FaPlayCircle } from 'react-icons/fa';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { Box, Button, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import IsSmallScreen, { GetVideoIdFromUrl, IsYouTubeVideo } from '../common/AppUtils';
import { AppLabels } from '../common/CultureInfo';
import YouTube from "react-youtube";

function MediaViewerImgVdo({ pageContent, index, displayLanguageCode, customClassName }) {
    var imagesToDisplay = [];
    var videosToDisplay = [];

    const mediaCount = pageContent && pageContent.media ? pageContent.media.length : 0;
    const [current, setCurrent] = useState(0);
    const [playSliding, setPlaySliding] = useState(true);

    const slide_Interval = 5000;

    const myRef = React.createRef();
    myRef.current = current;

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (playSliding) {
                var nextSlideNumber = myRef.current === mediaCount - 1 ? 0 : myRef.current + 1;
                setSlideIndex(nextSlideNumber);
            }
        }, slide_Interval);

        // Cleanup function to clear the timeout if the component unmounts
        return () => clearTimeout(timeoutId);
    }, [current, playSliding]); // Empty dependency array = the effect runs only once, we want to change the index every N seconds

    var nextSlideNumber = current === mediaCount - 1 ? 0 : current + 1;
    var prevSlideNumber = current === 0 ? mediaCount - 1 : current - 1;


    const setSlideIndex = (newSlideNumber) => {
        setCurrent(newSlideNumber);
        myRef.current = newSlideNumber;
        console.log(newSlideNumber);

    };

    const pauseSlide = () => {
        setPlaySliding(false);
    };
    const playSlide = () => {
        setPlaySliding(true);
    };

    const togglePlayState = () => {
        var currentStatus = playSliding;
        setPlaySliding(!currentStatus);
        console.log(currentStatus);
    }

    const captionStyle = {
        fontSize: '2em',
        fontWeight: 'bold',
    }
    const slideNumberStyle = {
        fontSize: '20px',
        fontWeight: 'bold',
    }

    const checkAndGotoSlideNumber = () => {
        var slideNoEntered = document.getElementById('gotoSlideNoInputBox').value;
        if (slideNoEntered > 0 && slideNoEntered <= mediaCount) {
            setSlideIndex(slideNoEntered - 1);
        }
    }

    const prepareMediaFileObjects = function (pageContent) {

        if (!(pageContent && pageContent.media && pageContent.media.length)) {
            pageContent.PreparedMediaFiles = [];
            pageContent.YouTubeMediaPresent = false;
            return pageContent;
        }

        var allMediaObjects = pageContent.media;
        var mediaFiles = [];
        var youtubeMediaPresent = false;
        var videoElementIndex = 0;
        for (var i = 0; i < allMediaObjects.length; i++) {
            var currentMedia = allMediaObjects[i];
            if (IsYouTubeVideo(currentMedia.url)) {
                currentMedia.IsYouTubeVideo = true;
                var videoId = GetVideoIdFromUrl(currentMedia.url);
                if (videoId && videoId != '') {
                    currentMedia.VideoId = videoId;
                    currentMedia.ElementId = "youtubeDivId" + (videoElementIndex++);
                    youtubeMediaPresent = true;
                }
                else {
                    currentMedia.VideoId = '';
                }
            }
            mediaFiles.push(currentMedia);
        }

        pageContent.PreparedMediaFiles = mediaFiles;
        pageContent.YouTubeMediaPresent = youtubeMediaPresent;
        return pageContent;
    }

    const isBigScreenDevice = !IsSmallScreen();
    const preparedPageContent = prepareMediaFileObjects(pageContent);
    const mediaFiles = preparedPageContent.PreparedMediaFiles;

    const youtubeVideoOptions = {
        height: "100%",
        width: "100%",
        playerVars: {
            autoplay: 0,
        },
    };

    const onPlayerReady = (event) => {
        const player = event.target;
        player.pauseVideo();
    };

    const onPlay = (e, i) => {
        pauseSlide();
        if (i > -1 && i < mediaFiles.length) {
            setSlideIndex(i);
        }
    }

    const onPaused = (e) => {
        playSlide();
    }

    return (
        <>
            {
                mediaFiles && mediaFiles.length ?
                    <>
                        <Grid2 key={"mediaGrid"} container sx={{ display: "flex" }}
                            lg={12} md={12} sm={12} xs={12}>
                            {/* <Grid2 key={"emptyGridItem"} item lg={1} md={1} sm={1} xs={1}></Grid2> */}
                            {/* <Grid2 key={"mediaGridItem"} item lg={11} md={11} sm={11} xs={11} > */}
                            <Grid2 item key="leftEmptySpace" lg={1} md={1} sm={1} xs={1}><span></span></Grid2>
                            <Grid2 key={"mediaGridItem"} item lg={10} md={10} sm={10} xs={10}>
                                {
                                    mediaFiles.map(function (mediaObj, i) {
                                        if (mediaObj.type == "image") {
                                            return <div
                                                key={"mediaElementDiv" + i}
                                                className={i === current ? 'slideImage slide active' : 'slideImage slide hidden'}
                                                style={{
                                                    backgroundImage:
                                                        'url("' + mediaObj.url + '")'
                                                    ,
                                                    backgroundSize: "contain",
                                                    backgroundRepeat: "no-repeat",
                                                    backgroundPosition: "center"
                                                }} />
                                        }
                                        else if (mediaObj.IsYouTubeVideo) {
                                            if (mediaObj.VideoId != '') {
                                                return <div key={"mediaElementDiv" + i} className={i === current ? 'slideImage slide active' : 'slideImage slide hidden'}>
                                                    <YouTube
                                                        videoId={mediaObj.VideoId}
                                                        opts={youtubeVideoOptions}
                                                        onReady={onPlayerReady}
                                                        onPlay={(e) => onPlay(e, i)}
                                                        onPause={(e) => onPaused(e)}
                                                        style={{ width: "100%", height: "100%" }}
                                                    />
                                                </div>
                                            }
                                            else {
                                                return <div key={"mediaElementDiv" + i} className={i === current ? 'slideImage slide active' : 'slideImage slide hidden'}>
                                                    <iframe key={"mediaIframe" + i} width="100%" height="100%" className={'mediaIframe'}
                                                        src={mediaObj.url + "?rel=0&enablejsapi=1"}>
                                                    </iframe>
                                                </div>
                                            }
                                        }
                                        else if (mediaObj.type == "video") {
                                            return <div key={"mediaElementDiv" + i} id={"mediaElementDiv" + i} className={i === current ? 'slideImage slide active' : 'slideImage slide hidden'}>
                                                <video key={"mediaVideoElement" + i} width={"100%"} height={"100%"} autoPlay={false} controls={true}
                                                    onPlay={(e)=> onPlay(e, i)}
                                                    onPause={(e)=> onPaused(e)}
                                                    src={mediaObj.url} >
                                                </video>
                                            </div>
                                        }
                                    })
                                }
                            </Grid2>
                            <Grid2 item key="rightEmptySpace" lg={1} md={1} sm={1} xs={1}><span></span></Grid2>
                        </Grid2>
                        <Box className={"mediaControlsContainerNewLine"}>
                            <Tooltip key="prevMediaButtonTooltip" title={AppLabels.tooltips.prevMedia[displayLanguageCode] + " " + (prevSlideNumber + 1)}>
                                <IconButton key={"prevSlideIconButton"} onClick={() => setSlideIndex(prevSlideNumber)}>
                                    <KeyboardArrowLeftIcon key={"prevSlideIcon"} className={'left-arrow'} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip key="pauseSlideLabelTooltip" title={AppLabels.tooltips.pauseSlide[displayLanguageCode]}>
                                <IconButton key={"playStateIconButton"} onClick={togglePlayState} >
                                    {
                                        playSliding ? <PauseIcon key={"pauseIcon1"} />
                                            : <PlayArrowIcon key={"playArrowIcon1"} />
                                    }
                                </IconButton>
                            </Tooltip>
                            <Tooltip key="nextMediaButtonTooltip" title={AppLabels.tooltips.nextMedia[displayLanguageCode] + " " + (nextSlideNumber + 1)}>
                                <IconButton key={"nextSlideIconButton"} onClick={() => setSlideIndex(nextSlideNumber)} >
                                    <KeyboardArrowRightIcon key={"nextSlideIcon"} left-arrow />
                                </IconButton>
                            </Tooltip>
                            <Tooltip key="currentMediaShownLabelTooltip" title={AppLabels.tooltips.currentMediaShown[displayLanguageCode]}>
                                <Box key={"slidesInfoSpan"} className='mediaNoIndicator'>
                                    {current + 1}/{mediaFiles.length}
                                </Box>
                            </Tooltip>
                            {
                                (isBigScreenDevice && mediaCount < 5) ?
                                    <div className='mediaIndexLinksContainer'>
                                        {
                                            Array.from({ length: mediaCount }, (item, mediaIndex) =>
                                                <Tooltip key={"mediaNavLinkTooltip" + mediaIndex} title={AppLabels.tooltips.goToSlideNo[displayLanguageCode] + " #" + (mediaIndex + 1)}>
                                                    <Button key={"mediaNavigationLink" + mediaIndex} style={{ borderRadius: "20px" }} onClick={() => setSlideIndex(mediaIndex)}>{(mediaIndex + 1)}</Button>
                                                </Tooltip>
                                            )}
                                    </div>
                                    : <>
                                        <Tooltip key="mediaNumberTooltip" title={AppLabels.tooltips.enterTheNumber[displayLanguageCode] + " [ " +
                                            AppLabels.tooltips.fromText[displayLanguageCode]
                                            + mediaCount + AppLabels.tooltips.toText[displayLanguageCode] + " ]"
                                        }>
                                            <TextField
                                                width="20vw"
                                                key='gotoSlideNoInputBox'
                                                id='gotoSlideNoInputBox'
                                                variant="standard"
                                                type='Number'
                                                placeholder={AppLabels.tooltips.enterTheNumber[displayLanguageCode]}
                                                size='small'
                                                margin='none'
                                                InputProps={{ inputProps: { min: 1, max: mediaCount, style: { "text-align": "center", minWidth: "10vw" } } }}
                                            ></TextField>
                                        </Tooltip>
                                        <Tooltip key="gotoSlideButtonTooltip" title={AppLabels.tooltips.goToSlideNo[displayLanguageCode]}>
                                            <Button onClick={() => checkAndGotoSlideNumber()}>{AppLabels.Labels.goTo[displayLanguageCode]}</Button>
                                        </Tooltip>
                                    </>
                            }

                        </Box>
                    </>
                    : <>No media found</>
            }
        </>
    );

}


export default MediaViewerImgVdo;
