import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function DebugHelper() {
  const theme = useTheme();
  const smMatches = useMediaQuery(theme.breakpoints.up('sm'));
  const mdMatches = useMediaQuery(theme.breakpoints.up('md'));
  const lgMatches = useMediaQuery(theme.breakpoints.up('lg'));
  const xsMatches = useMediaQuery(theme.breakpoints.up('xs'));

  console.log("Screen size[" + ("xs:" + xsMatches + "-sm:" + smMatches+"-md:"+ mdMatches+"-lg:"+ lgMatches+"]"));

  return <>
  </>;
}
