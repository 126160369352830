import React from "react";
import { useState, useEffect } from "react";
import IsSmallScreen, { TraceLog } from "../common/AppUtils";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import Marquee from "react-fast-marquee";
import { Link } from "react-router-dom";
import Modal from '@mui/material/Modal';
import { orange } from "@mui/material/colors";
import { AppLabels } from "../common/CultureInfo";
import TileDisplay from "./TileDisplay";

function WorkSamples({displayLanguageCode, pageContent, showLink=false, linkGenMethod=null, textMaxLength=50, rootElementKey=''})
{
    const [pauseScrolling, SetPauseScrolling] = useState(false);
    const [textToDisplay, SetTextToDisplay] = useState('');
    const [linkToShow, SetLinkToShow] = useState('');

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {setOpen(false); SetPauseScrolling(!pauseScrolling);};

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "80vw",        
        border: '2px solid #000',
        backgroundColor: "white",
        color: "black",
        boxShadow: 24,
        p: 4,
      };

    var shouldPlayMarque = !pauseScrolling;

    const onImageClick = function(item)
    {
        SetPauseScrolling(!pauseScrolling);
        SetTextToDisplay(item.title[displayLanguageCode]);
        if(showLink)
        {
            SetLinkToShow(linkGenMethod(item))
        }
        handleOpen();
    }

    const maxCharsAllowed = textMaxLength;
    const getTextToDisplay = function (theText)
    {
        var endIndex = theText.indexOf(' ', maxCharsAllowed);
        if(endIndex < 0)
        {
            endIndex = theText.indexOf('.', maxCharsAllowed);
        }
        if(endIndex < 0)
        {
            endIndex = maxCharsAllowed;
        }
        return theText.length < maxCharsAllowed ? theText : theText.slice(0, endIndex);
    }
    
    return(<>
        {pageContent && pageContent.length > 0 
            && 
            <div style={{display:"block", width:"85vw", overflowX:"hidden"}}>
                <Marquee 
                    key={"workSamplesMarqueViewer"+rootElementKey}
                    // speed={0.01} 
                    // delay={1000}
                    direction={"left"} 
                    children={"span"} 
                    play={shouldPlayMarque}
                    pauseOnHover={true}
                    pauseOnClick={true}
                    scrollWhen={"always"}>
                    {
                        pageContent.map((item, index) =>{
                            return <TileDisplay key={"tile"+ rootElementKey + index} 
                                displayLanguageCode={displayLanguageCode}
                                tileItem={item}
                                index={index}
                                onTileImageClicked={onImageClick}
                                showLink={showLink}
                                linkGenMethod={linkGenMethod}
                                textMaxLength={textMaxLength} 
                                rootElementKey={rootElementKey}
                                tileHeight={"20vw"}
                                tileWidth={"30vw"}
                                />
                            }
                        )
                    }            
                </Marquee>
                <Modal
                    key={"helpTextDisplayModalPopup"}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    
                    <Box key={"surroundingBoxTextDisplay"} sx={style}
                        width={"30vw"}
                        minHeight={"15vw"}>

                        <Box key={"closeButtonInModalPopup"}
                            style={{padding:"1px 1vw", textDecoration:"underline dotted", fontStyle:"italic", float:"right"}}                            
                            component={Link}                        
                            onClick={()=>{handleClose()}}
                        >X</Box>

                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {textToDisplay}
                    </Typography>
                    
                    {
                        showLink && <Button                                               
                        key={"workSampleLinkInPopup"}
                        component={Link}
                        underline={"hover"}
                        style={{padding:"1px 1vw", textDecoration:"underline dotted", fontStyle:"italic"}}
                        to={linkToShow}>
                           {AppLabels.buttonLabels.moreDetails[displayLanguageCode]}
                        </Button  >
                    }
                    </Box>
                </Modal>
            </div>
        }
        </>
        )
}

export default WorkSamples; 