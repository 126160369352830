import { useState } from "react";
import { Carousel } from 'react-carousel-minimal';
import SimpleImageSlider from "react-simple-image-slider";

function MediaViewerCarouselMin(content, index, displayLanguageCode, customClassName)
{
    var imagesToDisplay = [];
    var videosToDisplay = [];

    if(content.content)
    {
        for(var i in content.content)
        {
            var contentItem = content.content[i];
            if(contentItem.type === 'image')
            {
                imagesToDisplay.push({
                    'image': contentItem.url,
                    'caption': ''
                });
            }
            else
            {
                videosToDisplay.push({'url': contentItem.url});
            }
        }
    }

    const captionStyle = {
        fontSize: '2em',
        fontWeight: 'bold',
      }
      const slideNumberStyle = {
        fontSize: '20px',
        fontWeight: 'bold',
      }

    return(
        <>
            {
                imagesToDisplay.length?
                <div 
                    style={{clear:"both", display:"block", width:"90vw", height:"70vw"}} >
                    <Carousel
                        data={imagesToDisplay}
                        time={3000}
                        width="80vw"
                        height="70vh"
                        captionStyle={captionStyle}
                        radius="2px"
                        slideNumber={true}
                        slideNumberStyle={slideNumberStyle}
                        captionPosition="bottom"
                        automatic={true}
                        dots={true}
                        pauseIconColor="green"
                        pauseIconSize="100px"
                        slideBackgroundColor="darkgrey"
                        slideImageFit="cover"
                        thumbnails={false}
                        thumbnailWidth="100px"
                        style={{
                            textAlign: "center",
                            maxWidth: "90vw",
                            maxHeight: "70vh",
                            margin: "2px auto"
                        }}
                    />
                </div>
              :<></>
            }
            {
                videosToDisplay.length ? <SimpleImageSlider
                width={"50%"}
                height={"50%"}
                images={videosToDisplay}
                showBullets={true}
                showNavs={true}
              />:<></>
            }
            {
                (!imagesToDisplay && !videosToDisplay) ? <p>No media found</p>:<></>
            }
        </>
    );

}


export default MediaViewerCarouselMin;
