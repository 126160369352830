export const DisplayLanguageList = [
  { 'label': 'English', "value": "en" },
  { "label": 'தமிழ்', "value": "ta" }
];

export const AppLabels = {
  "header": {
    "en": "Nel Jayaraman Foundation",
    "ta": "நெல் ஜெயராமன் அறக்கட்டளை"
  },
  "movementName": {
    "en": "Nel Jayaraman traditional paddy conservation center",
    "ta": "நெல் ஜெயராமன் பாரம்பரிய நெல் பாதுகாப்பு மையம்"
  },
  "buttonLabels": {
    "next": { "en": "Prev", "ta": "முந்தையது" },
    "prev": { "en": "Next", "ta": "அடுத்தது" },
    "moreDetails": { "en": "More info", "ta": "மேலும் தகவலுக்கு" },
    "close": { "en": "Close", "ta": "மூடுக" }

  },
  "Labels":
  {
    "eventDate": { "en": "Date", "ta": "நாள்" },
    "eventPlace": { "en": "Place", "ta": "இடம்" },
    "language": { "en": "Language", "ta": "மொழி" },
    "currentThemeIsDarkHelpText": { "en": "Viewing in Dark mode. Click here to view in light mode.", "ta": "நீங்கள் பக்கத்தை இருண்ட வண்ண பயன்முறையில் பார்க்கிறீர்கள். ஒளி வண்ண பயன்முறையில் பார்க்க இங்கே கிளிக் செய்யவும்." },
    "currentThemeIsLightHelpText": { "en": "Viewing in Light mode. Click here to view in Dark mode.", "ta": "நீங்கள் பக்கத்தை வெளிர் வண்ண பயன்முறையில் பார்க்கிறீர்கள். அடர் வண்ண பயன்முறையில் பார்க்க இங்கே கிளிக் செய்யவும்." },
    "currentThemeIsDarkText": { "en": "Dark mode", "ta": "இரவு" },
    "currentThemeIsLightText": { "en": "Light mode", "ta": "பகல்" },
    "settings": { "en": "Settings", "ta": "அமைப்புகள்" },
    "theme": { "en": "Theme", "ta": "வண்ண திட்டம்" },
    "phone": { "en": "Phone", "ta": "தொலைபேசி" },
    "email": { "en": "Email", "ta": "மின்னஞ்சல்" },
    "address": { "en": "Address", "ta": "முகவரி" },
    "shareText": { "en": "Share with your friends on", "ta": "உங்கள் நண்பர்களுடன் பகிர" },
    "awardsBy": { "en": "given", "ta": "" },
    "govt": { "en": "by government", "ta": "அரசு விருதுகள்" },
    "pvt": { "en": "by private bodies", "ta": "தனியார் அமைப்பு விருதுகள்" },
    "goTo": { "en": "Go", "ta": "செல்க" },
  },
  "tooltips": {
    "next": { "en": "Previous article", "ta": "முந்தைய நிகழ்வு" },
    "prev": { "en": "Next article", "ta": "அடுத்த நிகழ்வு" },
    "moreDetails": { "en": "Click here to more information", "ta": "மேலும் தகவலுக்கு இங்கே கிளிக் செய்யவும்" }
    , "contentCountInTitle": { "en": "No.of content out of total contents.", "ta": "தற்போதைய எண் மற்றும் மொத்த உள்ளடக்கங்களின் எண்ணிக்கை." }
    , "nextMedia": { "en": "Next", "ta": "அடுத்ததுக்கு செல்ல" }
    , "prevMedia": { "en": "Previous", "ta": "முந்தையதுக்கு செல்ல" }
    , "currentMediaShown": { "en": "Currently shown slide number", "ta": "தற்போதைய ஊடக எண்" }
    , "goToSlideNo": {"en": "Go to slide ", "ta":"உள்ளிட்ட எண்ணுக்குச் செல்ல "}
    , "pauseSlide":{"en":"To pause the slide show", "ta":"இடைநிறுத்துவதற்கு"}
    , "enterTheNumber":{"en":"Slide #", "ta":"எண்ணை உள்ளிடவும்"}
    , "fromText":{"en": " from 1 to ", "ta": " 1 முதல் "}
    , "toText":{"en": "", "ta": " வரை"}


},
"contact":
{
  "name":
  {
    "en": "CMA S. Rajiv",
      "ta": "CMA செ. ராஜிவ்"
  },
  "designation":
  {
    "en": "Managing Trustee & State Coordinator",
      "ta": "நிர்வாக அறங்காவலர் & மாநில ஒருங்கிணைப்பாளர்"
  },
  "institution":
  {
    "en": "Nel Jayaraman Foundation",
      "ta": "நெல் ஜெயராமன் அறக்கட்டளை"
  },
  "whatsappPhone": { "en": "919952787998", "ta": "919952787998" },
  "phone": { "en": "919952787998", "ta": "919952787998" },
  "phoneToDisplay": { "en": "+91 99527 87998", "ta": "+91 99527 87998" },
  "phone2ToDisplay": { "en": "+91 94433 20954", "ta": "+91 94433 20954" },
  "emailAddress": { "en": "neljayaramanfoundation@gmail.com", "ta": "neljayaramanfoundation@gmail.com" },
  "emailAddress2": { "en": "rajivicwai2013@gmail.com", "ta": "rajivicwai2013@gmail.com" },
  "address": {
    "en": "G.G Building, TVR Road, Thiruthuraipoondi - 614 713, Thiruvarur Dist, Tamilnadu.",
      "ta": "ஜி.ஜி கட்டிடம், டிவிஆர் சாலை, திருத்துறைப்பூண்டி - 614 713, திருவாரூர் மாவட்டம், தமிழ்நாடு."
  }
},
"aboutUsContent":
{
  "ta": "நெல் ஜெயராமன் அறக்கட்டளை பாரம்பரிய நெல் பாதுகாப்பு, பாரம்பரிய விவசாயம் குறித்த விழிப்புணர்வு நிகழ்ச்சிகளை நடத்துகிறது. நெல் ஜெயராமன் அறக்கட்டளை திரு. ராஜீவ் (மாநில ஒருங்கிணைப்பாளர்) அவர்களால் நடத்தப்படுகிறது.",
    "en": "Nel Jayaraman foundation conducts awareness programs about traditional paddy protection, traditional agriculture. Nel Jayaraman foundation is run by Mr. Rajiv (state coordinator)."
},
"contactPageTitle":
{
  "ta": "எங்களை தொடர்பு கொள்ள",
    "en": "Contact us"
},
"eventNameSearch":
{
  "ta": "இங்கே தேடவும்..",
    "en": "Search here.."
},
"page":
{
  "events":
  {
    "ta": "நிகழ்வுகள்",
      "en": "Events"
  },
  "festivals":
  {
    "ta": "நெல் திருவிழாக்கள்",
      "en": "Paddy Festivals"
  },
  "articles": {
    "en": "Articles",
      "ta": "கட்டுரைகள்"
  }
},
"readMoreLabel":
{
  "ta": "...மேலும் படிக்க",
    "en": "...read more"
},
"readLessLabel":
{
  "ta": "...குறைவாக காட்டு",
    "en": "...show less"
},
"workSamples":
{
  "en": "Our contributions",
    "ta": "எங்கள் பங்களிப்புகள்"
},
"homeAwardsSamples":
{
  "en": "Awards and recognitions",
    "ta": "விருதுகள் மற்றும் அங்கீகாரங்கள்"
}
};

export const MenuLabels = {
  "home": {
    "en": "Home",
    "ta": "முகப்பு பக்கம்"
  },
  "events": {
    "en": "Events",
    "ta": "நிகழ்வுகள்"
  },
  "articles": {
    "en": "Articles",
    "ta": "கட்டுரைகள்"
  },
  "festival": {
    "en": "Paddy Festival",
    "ta": "நெல் திருவிழா"
  },
  "festivals":
  {
    "ta": "நெல் திருவிழாக்கள்",
    "en": "Paddy Festivals"
  },
  "gallery": {
    "en": "Gallery",
    "ta": "புகைப்பட தொகுப்பு"
  },

  "about": {
    "en": "About",
    "ta": "எங்களை பற்றி"
  },

  "contactus": {
    "en": "Contact us",
    "ta": "எங்களை தொடர்பு கொள்ள"
  },
  "awards": {
    "en": "Awards",
    "ta": "விருதுகள்"
  },
  "news": {
    "en": "In news",
    "ta": "பத்திரிகைகளில் எங்களைப் பற்றி"
  },
  "members": {
    "ta": "எங்கள் அணி",
    "en": "Our team"
  },
  "theme": { "en": "Theme", "ta": "வண்ண திட்டம்" },
  "darkTheme": { "en": "Dark", "ta": "இரவு" },
  "lightTheme": { "en": "Light", "ta": "பகல்" }

};

export const MemberPageLabels = {
  "membersSubtitle": { "ta": "உறுப்பினர்கள்", "en": "Members" },
  "name": { "ta": "பெயர்", "en": "Name" },
  "place": { "ta": "பகுதி", "en": "Place" },
  "district": { "ta": "மாவட்டம்", "en": "District" }
};
