import logo from './logo.jpg';
import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route, Switch, BrowserRouter, Link, useParams, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import CopyrightIcon from '@mui/icons-material/Copyright';
import ShareIcon from '@mui/icons-material/Share';
import CloseIcon from '@mui/icons-material/Close';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import React, { useState, useEffect } from "react";
import LanguageSelector2 from './components/LanguageSelector2';

import { styled } from '@mui/material/styles';
// import Box from '@mui/material/Box';
// import Paper from '@mui/material/Paper';

import * as AppUtils from './common/AppUtils.jsx'
import * as CultureInfo from './common/CultureInfo.jsx'
import AnnouncementViewer from './components/AnnouncementViewer';
import Marquee from "react-fast-marquee";

// Theme - starts
import IconButton from '@mui/material/IconButton';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { Divider, Grid, Tooltip, emphasize } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
// Theme - ends

import { NestedMenu } from './components/NavBar2_not_used'
import AppRoutes from './components/AppRoutes'
import DebugHelper from './components/DebugHelper';
import MenuBarSide from './components/MenuBarSide';
import IsSmallScreen from './common/AppUtils';
import SettingsSection from './components/SettingsSection';
import ScrollToTop from 'react-scroll-to-top';
import ThemeSelector from './components/ThemeSelector';


function App({ themeModeName, onToggleThemeCallback }) {

  const [pageContent, setPageContent] = useState(null);
  const [displayLanguageCode, setDisplayLanguage] = useState(AppUtils.GetItemFromLocalStorage('selectedLanguage', 'en'))
  const [showShareSection, setShowShareSection] = useState(false);
  //const location = useLocation();  


  useEffect(() => {
    fetch('/data/masterdata.json', { cache: "no-store" })
      .then(response => response.json())
      .then(json => {
        setPageContent(json);
        if (json.data && json.data.length) {
          //setCurrentIndex(0);
          console.log("App - Received content count: " + json.data.length);
        }
      })
      .catch(error => console.error(error));
  }, []);

  useEffect(() => {
    updatePageTitleAndDescription();
  }, []);

  const ChangeLanguageIndexCallback = (selectedEventObj) => {
    AppUtils.TraceLog(" Preferred Language will be " + selectedEventObj);
    setDisplayLanguage(selectedEventObj);
    AppUtils.SetItemFromLocalStorage("selectedLanguage", selectedEventObj);
    updatePageTitleAndDescription();
  }

  var isSmallSizeScreen = IsSmallScreen();
  var isBigSizeScreen = !isSmallSizeScreen;
  var announcements = pageContent && pageContent.announcements ? pageContent.announcements : [];
  var fbShareUrl = "https://www.facebook.com/sharer/sharer.php?u=" + window.location.href;
  var twitterShareUrl = "https://twitter.com/intent/tweet?source=" + window.location.href + "&text=Like%20and%20share%20NelJayaramanFoundation&hashtags=&url=" + window.location.href;
  var linkedInShareUrl = "https://www.linkedin.com/shareArticle?mini=true&url=" + window.location.href;
  var whatsAppUrl = "https://web.whatsapp.com/send?text=Nel+Jayaraman+Foundation%0A"+window.location.href;

  return (<>
    <div className="appBody">
      {
        !showShareSection &&
        <Tooltip title={CultureInfo.AppLabels.Labels.shareText[displayLanguageCode]}>
          <ShareIcon variant='contained' onClick={() => { setShowShareSection(!showShareSection); }}
            sx={{
              position: "fixed", top: "30vh", opacity: "0.5", right: 0, zIndex: 2002, cursor: "pointer", paddingLeft: "10px", paddingRight: "5px",
              backgroundColor: 'background.default', color: 'text.primary', borderRadius: "15px"
            }}>Share</ShareIcon>
        </Tooltip>
      }
      {
        showShareSection && <div id="shareSection" name="shareSection"
          style={{
            position: "fixed", top: "30vh", width: "50px", right: 1, zIndex: 2001,
            borderRadius: "15px"
          }}>
          <Grid2 container lg={12} md={12} sm={12} xs={12} className={"roundedContainer"}
            sx={{ backgroundColor: 'background.default', color: 'text.primary' }}>
            <Grid2 item>
              <Tooltip title={"Close share options"}>
                <CloseIcon sx={{ cursor: "pointer" }} onClick={() => { setShowShareSection(!showShareSection); }} />
              </Tooltip>
              <Divider></Divider>
            </Grid2>
            <Grid2 item sx={{ paddingTop: "5px" }}>
              <Grid2 item>
                <div className="socialMediaIconContainer">
                  <a key={"whatsAppShareUrl"} href={whatsAppUrl} target="_blank" className={"special-link whatsAppIcon"}>
                    <WhatsAppIcon></WhatsAppIcon>
                  </a></div>
              </Grid2>
              <div className="socialMediaIconContainer">
                <a key={"fbPageShareUrl"} href={fbShareUrl} target="_blank" className={"special-link fbIcon"}>
                  <FacebookIcon></FacebookIcon>
                </a>
              </div>
            </Grid2>
            <Grid2 item>
              <div className="socialMediaIconContainer">
                <a key={"twitterShareUrl"} href={twitterShareUrl} target="_blank" className={"special-link twitterIcon"}>
                  <TwitterIcon></TwitterIcon>
                </a></div>
            </Grid2>
            <Grid2 item>
              <div className="socialMediaIconContainer">
                <a key={"linkedInShareUrl"} href={linkedInShareUrl} target="_blank" className={"special-link liIcon"}>
                  <LinkedInIcon></LinkedInIcon>
                </a></div>
            </Grid2>
          </Grid2>
        </div>
      }
      {/* Body starts */}
      {/* <Grid2 container lg={12} md={12} sm={12} xs={12} sx={{alignItems:"stretch"}} > */}
      <Grid2 container key={"mainContainerWithTheme"} className={"roundedContainer"}
        sx={{ backgroundColor: 'background.default', color: 'text.primary' }}
      >
        <Router>
          {/* Display the navigation links */}
          {
            isBigSizeScreen &&
            <Grid2 container lg={12} md={12} sm={12} xs={12} sx={{ alignItems: "stretch" }} >
              <Grid2 item lg={1} md={1} sm={1} xs={3} sx={{ justifySelf: 'flex-start' }}>
                <Tooltip key="appLogoTooltip" title={CultureInfo.AppLabels.header[displayLanguageCode]}>
                  <img src={logo} className="App-logo" alt="logo" style={{ borderRadius: "10px" }} />
                </Tooltip>
              </Grid2>

              <Grid2 item lg={3} md={3} sm={3} xs={3} sx={{ justifySelf: 'flex-start', display: { xs: "block", lg: "block", md: "block" } }}>
                <span>{CultureInfo.AppLabels.header[displayLanguageCode]}</span>
                <div style={{ clear: "both", fontSize: "small" }}>
                  <sub>{CultureInfo.AppLabels.movementName[displayLanguageCode]}</sub>
                </div>
              </Grid2>
              <Grid2 item lg={6} md={6} sm={6} xs={6} sx={{ justifySelf: 'flex-end' }} className="pageLinks">
                <Navbar displayLanguageCode={displayLanguageCode} themeModeName={themeModeName} />
                
              </Grid2>
              <Grid2 item lg={2} md={2} sm={2} xs={2} sx={{ justifySelf: 'flex-end' }}>
                <LanguageSelector2
                  key={"languageSelector1LargeScreen"} customClassName='languageSelectorStyle'
                  hideLabel={true}
                  parentKey='largeScreenOptions'
                  languages={CultureInfo.DisplayLanguageList}
                  selectedLanguageCode={displayLanguageCode}
                  OnSelectedIndexChanged={(selectedEventObj) => { ChangeLanguageIndexCallback(selectedEventObj); }} />

                <ThemeSelector key={"themeSelector1LargeScreen"}
                  hideLabel={true}
                  parentKey='largeScreenOptions'
                  displayLanguageCode={displayLanguageCode}
                  themeModeName={themeModeName}
                  OnToggleThemeCallback={() => { onToggleThemeCallback(); }} />
              </Grid2>
            </Grid2>
          }

          {
            isSmallSizeScreen && <>
              <Grid2 container lg={12} md={12} sm={12} xs={12} sx={{ alignItems: "stretch" }} >
                <Grid2 item lg={1} md={1} sm={1} xs={1} sx={{ justifySelf: 'flex-end' }}>
                  <MenuBarSide
                    displayLanguageCode={displayLanguageCode}
                    themeModeName={themeModeName}
                    OnThemeChanged={onToggleThemeCallback}
                    OnSelectedLanguageIndexChanged={ChangeLanguageIndexCallback} />
                </Grid2>
                <Grid2 item lg={2} md={2} sm={2} xs={2} sx={{ justifySelf: 'flex-start' }}>
                  <Tooltip key="appLogoTooltip" title={CultureInfo.AppLabels.header[displayLanguageCode]}>
                    <img src={logo} className="App-logo" alt="logo" />
                  </Tooltip>
                </Grid2>

                <Grid2 item lg={9} md={9} sm={9} xs={8} sx={{ justifySelf: 'flex-start', display: { xs: "block", lg: "block", md: "block" } }}>
                  <span>{CultureInfo.AppLabels.header[displayLanguageCode]}</span>
                  <div style={{ clear: "both", fontSize: "small" }}>
                    <sub>{CultureInfo.AppLabels.movementName[displayLanguageCode]}</sub>
                  </div>
                </Grid2>
              </Grid2>
            </>
          }

          {/* <Grid2 container lg={12} md={12} sm={12} xs={12}
                  sx={{alignItems:"stretch"}} > */}
          {/* Following routes are required for the React js router to understand the available routes  */}
          <AppRoutes displayLanguageCode={displayLanguageCode} pageContent={pageContent}></AppRoutes>
          {/* </Grid2> */}
        </Router>

        {/* </Grid2> */}
        {/* Bodt ends */}
        <ScrollToTop
          color={themeModeName === "dark" ? "white" : "black"}
          smooth={true} style={{ backgroundColor: "green", borderColor: "green", opacity: "0.5", borderBlockStyle: 'solid', borderBlockWidth: "2px" }} className={"scrollButtonStyle"} />
        <DebugHelper></DebugHelper>
      </Grid2>
    </div>

    <div>
      <p>
        <CopyrightIcon />{CultureInfo.AppLabels.header[displayLanguageCode]} - All rights reserved.
      </p>
    </div>
  </>
  );

  function updatePageTitleAndDescription() {
    document.title = CultureInfo.AppLabels.header[displayLanguageCode]; // Update the document title
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', CultureInfo.AppLabels.header[displayLanguageCode]);
    }
  }
}

export default App;
