import { Tooltip } from "@mui/material";
import React from "react";
import { AppLabels } from "../common/CultureInfo";

const ContentTitleDisplay = ({ subtitle, currentItemIndex, totalItemCount, displayLanguageCode }) => {
    return <>
        <h3>{subtitle}
            <Tooltip title={AppLabels.tooltips.contentCountInTitle[displayLanguageCode]}>
                <sub>
                    <span style={{ fontSize: "smaller", fontStyle: "italic" }}>{" ["}{currentItemIndex + 1} {"/"}
                        {totalItemCount}{"]"}
                    </span>
                </sub>
            </Tooltip>
        </h3>
    </>
}

export default ContentTitleDisplay;