import React from "react";
import { AppLabels } from '../common/CultureInfo';

const About = ({displayLanguageCode}) => {
	return (
		<div>
			<h3>
				{AppLabels.aboutUsContent[displayLanguageCode]}
			</h3>
		</div>
	);
};

export default About;
