import React from "react";
import { Nav, NavLink, NavMenu } from "./NavBarElements";
import {MenuLabels} from '../common/CultureInfo'
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import * as AppUtils from '../common/AppUtils';
import { useTheme, ThemeProvider, createTheme } from '@mui/material/styles';


const Navbar = ({displayLanguageCode='en'}) => {

	const theme = useTheme();

	var selectedLanguageCode = displayLanguageCode?displayLanguageCode:'en';
	var pages = AppUtils.GetAllPages();

	return (
		<>
			{
				pages.map((p, index)=>{return <Button 
				sx={{
					backgroundColor: theme.palette.background.default, color: theme.palette.text.primary}} 
					key={p.linkName+index}
					component={Link}
					underline={"hover"}
					to={p.linkTo}>
						{MenuLabels[p.linkName][selectedLanguageCode]}
					</Button  >
				})
			}
		</>
	);
};

export default Navbar;
