import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import { useTheme, ThemeProvider, createTheme } from '@mui/material/styles';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import App from './App'
import * as AppUtils from './common/AppUtils.jsx'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';


const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

function ThemedApp() {
  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);
  return (
        <>
        {/* <Grid2 container
          sx={{
            display: 'flex',
            width: '98%',
            height: '99%',
            alignItems: 'normal',
            justifyContent: 'space-evenly',
            bgcolor: 'background.default',
            color: 'text.primary',
            paddingLeft: 3,
            paddingRight: 3,
            backgroundImage: '/public/page-background-image-low.jpg'
          }}
        > */}
          <App themeModeName={theme.palette.mode} onToggleThemeCallback={() => colorMode.toggleColorMode()}></App>
        {/* </Grid2> */}
        </>
  );
}

export default function ToggleColorMode() {
  const [mode, setMode] = React.useState(AppUtils.GetItemFromLocalStorage('preferredViewingMode', 'light'))

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        var newMode = mode === 'light' ? 'dark' : 'light';
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
        AppUtils.SetItemFromLocalStorage("preferredViewingMode", newMode);
        AppUtils.TraceLog("Setting the mode to " + newMode);
      },
    }),
    [],
  );

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
      }),
    [mode],
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <ThemedApp />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}
