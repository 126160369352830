import React, { useEffect, useState } from "react";

import ContentViewer from "../components/ContentViewer"
import LanguageSelector from "../components/LanguageSelector";
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { BrowserRouter as Router, Routes, Route, Switch, BrowserRouter, Link, useParams, useNavigate} from 'react-router-dom';
import { CircularProgress } from "@mui/material";



import './home.css'
import './home2.css'


import { styled } from '@mui/material/styles';
// import Box from '@mui/material/Box';
// import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

import * as CultureInfo from '../common/CultureInfo.jsx'
import { TraceLog } from "../common/AppUtils";
import AnnouncementViewer from '../components/AnnouncementViewer'
import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import * as AppUtils from "../common/AppUtils";
import MediaViewerSimpleImageSlider from "../components/MediaViewerImageSlider";
import MediaViewerCarousel from "../components/MediaViewerCarousel";
import MediaViewerCarouselMin from "../components/MediaViewerCarouselMin";
import AnnouncementViewer2 from "../components/AnnouncementViewer2";
import SearchPageTitleNavigatorSimple from "../components/SearchPageTitleNavigatorSimple";
import IsSmallScreen from '../common/AppUtils';
import { Divider } from "@mui/material";
import ContentViewerDynamic from "../components/ContentViewerDynamic";
import VisionAndMission from "../components/VisionAndMission";
import WorkSamples from "../components/WorkSamples";
import Activities from "../components/Activities";

const Home2 = ({ displayLanguageCode}) => {

    const [workSamplesContent, SetWorkSamplesContent] = useState(null);
    const [awardsContent, SetAwardsContent] = useState(null);

    const [isRequestInProgress, SetIsRequestInProgress] = useState(false);
    const [requestsCount, SetRequestsCount] = useState(0);

    useEffect(() => {
        SetIsRequestInProgress(true);
        SetRequestsCount(() => requestsCount + 1);
        fetch('/data/masterdata.json')
          .then(response => response.json())
          .then(json => {
                SetWorkSamplesContent(AppUtils.FormTileData(json, true));
                if(json.data && json.data.length)
                {
                    //setCurrentIndex(0);
                    console.log("App - Received content count: "+ json.data.length);
                }
                SetRequestsCount(() => requestsCount - 1);
                SetIsRequestInProgress(requestsCount -1 > 0);
          })
          .catch(error => {
            SetRequestsCount(() => requestsCount - 1);
            SetIsRequestInProgress(requestsCount - 1 > 0);
            console.error(error);
        });
      }, []);

      useEffect(() => {
        SetIsRequestInProgress(true);
        SetRequestsCount(() => requestsCount + 1);
        fetch('/data/awards.json')
          .then(response => response.json())
          .then(json => {
                SetAwardsContent(AppUtils.FormTileData(json, true));
                if(json.data && json.data.length)
                {
                    //setCurrentIndex(0);
                    console.log("App - Received content count: "+ json.data.length);
                }
                SetRequestsCount(() => requestsCount - 1);
                SetIsRequestInProgress(requestsCount -1 > 0);
          })
          .catch(error => {
            SetRequestsCount(() => requestsCount - 1);
            SetIsRequestInProgress(requestsCount - 1 > 0);
            console.error(error);
        });
      }, []);

      const eventsLinkGenerateMethod = function(data)
      {
        return '/event/' + data.dataId;
      }

      const awardsLinkGenerateMethod = function(data)
      {
        return '/awards';
      }


    const pageContent = {
        "dataId": "homePageContent",
        "media": [
        ],
        "content": {
          "en": {
            "type": "relativePathUrl",
            "content": "/data/static/homePage/en/content.html"
          },
          "ta": {
            "type": "relativePathUrl",
            "content": "/data/static/homePage/ta/content.html"
          }
        },
        "eventDate": "2023-09-16T00:00:00Z",
        "eventPlace": {
          "ta": "நெல் ஜெயராமன் அறக்கட்டளை",
          "en": "Nel Jayaraman Foundation"
        },
        "eventName": {
            "ta": "நெல் ஜெயராமன் அறக்கட்டளை",
            "en": "Nel Jayaraman Foundation"
        }
      };      

return (<>
        <div className="container">
            <h2>{pageContent.eventName[displayLanguageCode]}</h2>
            <p sx={{}}>
                <Box
                component="img"
                className="image"
                alt="Home page image."
                src="home_nj.jpeg"
                />
            </p>
            <div className="text">                
                <ContentViewerDynamic
                            contentUrl={pageContent.content[displayLanguageCode].content}
                            index ={0}
                            eventDate={pageContent.eventDate}
                            displayLanguageCode={displayLanguageCode}
                            customClassName="text"
                            showReadMore={true}
                            readMoreMinCharacters={500} />
            </div>
        </div>
        <VisionAndMission displayLanguageCode={displayLanguageCode}/>
        
        <Activities displayLanguageCode={displayLanguageCode}/>
        <div className="container">
            <h2>{CultureInfo.AppLabels.workSamples[displayLanguageCode]}</h2>
            <WorkSamples 
                key={"eventSamplesSection"}
                displayLanguageCode={displayLanguageCode}
                pageContent={workSamplesContent}
                showLink={true}
                linkGenMethod={eventsLinkGenerateMethod}
                rootElementKey={"contributions"}
                />
        </div>
        <div className="container">
            <h2>{CultureInfo.AppLabels.homeAwardsSamples[displayLanguageCode]}</h2>
            <WorkSamples 
                key={"awardsamplesSection"}
                displayLanguageCode={displayLanguageCode}
                pageContent={awardsContent}
                showLink={false}
                linkGenMethod={awardsLinkGenerateMethod}
                rootElementKey={"awardsamplesSection"}
                />
        </div>
        {
            isRequestInProgress && <CircularProgress color="success" />
        }
        </>
    )
}

export default Home2;
