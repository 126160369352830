import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

var traceLogEnabled = true;

//export const BaseUrl = ()=>{return "http://localhost:3000";}
export const BaseUrl = () => { return "https://neljayaramanfoundation.com"; }


export const GetAllPages = function () {
  const Pages = [
    { "linkName": "home", "linkTo": "/home" },
    { "linkName": "events", "linkTo": "/events" },
    { "linkName": "articles", "linkTo": "/articles" },
    // {"linkName": "festival","linkTo":"/festival"},
    { "linkName": "festival", "linkTo": "/festivals" },
    { "linkName": "awards", "linkTo": "/awards" },
    { "linkName": "news", "linkTo": "/news" },
    { "linkName": "gallery", "linkTo": "/gallery" },
    { "linkName": "contactus", "linkTo": "contact" },
    { "linkName": "members", "linkTo": "/members" }
  ];
  return Pages;
}

export const TraceLog = function (message) {
  try {
    if (traceLogEnabled) {
      console.log(message);
    }
  } catch (e) { }
}

export const IsDate = function (dateToCheck) {
  try {
    return dateToCheck && dateToCheck instanceof Date && !isNaN(dateToCheck.valueOf());
  } catch (e) {
    return false;
  }
}

export const FormTileData = function (thePageContent, shouldPickRandomImage = true) {
  var result = [];
  if (thePageContent && thePageContent.data && thePageContent.data.length) {

    for (var index in thePageContent.data) {
      var currentItem = thePageContent.data[index];
      try {
        var eventDateObj = new Date(currentItem.eventDate);
        currentItem.eventDateObject = eventDateObj;
        currentItem.eventDateDisplayString = eventDateObj.getDate() + "/" + eventDateObj.getMonth() + "/" + (eventDateObj.getFullYear() - 2000);
      } catch (e) {
        currentItem.eventDateObject = new Date(1900, 0, 1);
        currentItem.eventDateDisplayString = '';
      }
    }

    var sortedContents = thePageContent.data.sort((a, b) => b.eventDateObject - a.eventDateObject);

    for (var index in sortedContents) {
      var element = sortedContents[index];
      if (element.media.length < 1)
        continue;

      // var onlyImages = [];
      
      // for(var i=0; i<element.media.length;i++)
      // {
      //   if(element.media[i].type === "image")
      //   {

      //   }
      // }

      var randomIndex = shouldPickRandomImage ? Math.floor(Math.random() * element.media.length) : 0;
      var randomMedia = element.media[randomIndex];
      result.push(
        {
          imgUrl: randomMedia.url,
          title: element.eventName,
          dataId: element.dataId,
          dataItem: element,
          mediaType: randomMedia.type
        });
    }
  }

  return result;
}

export const IsYouTubeVideo = function (mediaUrl) {
  return (mediaUrl.includes("youtube.com") || mediaUrl.includes("youtu.be"));
}

export const GetVideoIdFromUrl = function (url) {
  var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url.match(regExp);
  return (match && match[7].length == 11) ? match[7] : '';
}

export const FormAlbumCover = function (thePageContent, itemsInCover = 2, shouldPickRandomImage = true) {
  var result = [];
  itemsInCover = itemsInCover < 1 ? 1 : itemsInCover;
  if (thePageContent && thePageContent.data && thePageContent.data.length) {

    var sortedContents = thePageContent.data.sort((a, b) => b.eventDateObject - a.eventDateObject);

    for (var index in sortedContents) {
      var element = sortedContents[index]
      if (element.media.length < 1) {
        continue;
      }

      var imagesInAlbum = [];
      var indexAlreadyPresent = [];
      for (var requiredItemsInCover = 0;
        requiredItemsInCover < itemsInCover && requiredItemsInCover < element.media.length;
        requiredItemsInCover++) {
        var randomIndex = shouldPickRandomImage ? Math.floor(Math.random() * element.media.length) : 0;
        if (indexAlreadyPresent.indexOf(randomIndex) === -1) {
          imagesInAlbum.push(
            {
              imgUrl: element.media[randomIndex].url,
              title: element.eventName,
              dataId: element.dataId
            });
        }
      }

      result.push({
        images: imagesInAlbum,
        dataItem: element
      });
    }
  }

  return result;
}

export const GetItemFromLocalStorage = function (itemName, defaultIfMissing) {
  try {
    var lang = localStorage.getItem(itemName);
    TraceLog('From local storage [' + itemName + ']=' + lang);
    if (lang) {
      return lang;
    }
    SetItemFromLocalStorage(itemName, defaultIfMissing);
    return defaultIfMissing;
  } catch (err) {
    TraceLog("Error reading [" + defaultIfMissing + "] from local storage." + err);
    SetItemFromLocalStorage(itemName, defaultIfMissing);
    return defaultIfMissing;
  }
}

export const SetItemFromLocalStorage = function (itemName, valueToSave) {
  try {
    var lang = localStorage.setItem(itemName, valueToSave);
  } catch (err) {
    TraceLog("Error setting [" + itemName + "] to local storage." + err);
  }
}

export const ParseContentIntoParagraphs = (theContent) => {
  const splitByNewlineContentArray = theContent ? theContent.split("\n") : [];
  var finalResult = [];

  for (var i in splitByNewlineContentArray) {
    var paraElements = splitByNewlineContentArray[i].replace(/<\/p>/g, '').replace(/<br\s*\/?>/gi, '<p>').split(/<p[^>]*>/g);
    for (var j in paraElements) {
      finalResult.push(paraElements[j]);
    }
  }

  return finalResult;
}

export const SortContentForGalleryView = function (thePageContent, displayLanguageCode) {
  var formedPageContentTemp = [];

  if (!thePageContent || !thePageContent.data)
    return formedPageContentTemp;

  //if(thePageContent && thePageContent.data)
  {

    for (var pc in thePageContent.data) {
      if (!thePageContent.data[pc].eventDateObject) {
        if (thePageContent.data[pc].eventDate) {
          thePageContent.data[pc].eventDateObject = new Date(thePageContent.data[pc].eventDate);
        }
        else {
          thePageContent.data[pc].eventDateObject = new Date('1900-01-01');
        }
      }
    }

    var sortedContents = thePageContent.data.sort((a, b) => b.eventDateObject - a.eventDateObject);



    for (var pc in sortedContents) {
      var pageContentItem = sortedContents[pc];

      if (pageContentItem.media && pageContentItem.media.length) {
        var currentContentTitle = (pageContentItem.eventName
          && pageContentItem.eventName[displayLanguageCode]
          ? pageContentItem.eventName[displayLanguageCode] : '');

        for (var mediaIndex in pageContentItem.media) {
          var mediaResource = {};
          mediaResource.img = pageContentItem.media[mediaIndex].url;
          mediaResource.title = currentContentTitle;
          // mediaResource.rows = 1;
          // mediaResource.cols = 1;
          mediaResource.featured = true;
          mediaResource.author = '';
          mediaResource.contentItemObject = pageContentItem.content;
          mediaResource.eventDate = new Date(pageContentItem.eventDate).toDateString();
          mediaResource.dataId = pageContentItem.dataId;

          formedPageContentTemp.push(mediaResource);
        }
      }
    }
  }
  return formedPageContentTemp;
}


export const ReplaceAll = function (find, replace, sourceString) {
  return sourceString.replace(new RegExp(find, 'g'), replace);
}


export default function IsSmallScreen() {
  const theme = useTheme();
  const smMatches = useMediaQuery(theme.breakpoints.up('sm'));
  const mdMatches = useMediaQuery(theme.breakpoints.up('md'));
  const lgMatches = useMediaQuery(theme.breakpoints.up('lg'));
  const xsMatches = useMediaQuery(theme.breakpoints.up('xs'));

  console.log("Screen size[" + ("xs:" + xsMatches + "-sm:" + smMatches + "-md:" + mdMatches + "-lg:" + lgMatches + "]"));
  return !lgMatches && !mdMatches && (smMatches || xsMatches);
}
