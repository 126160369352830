import React from "react";
import { BrowserRouter as Router, Routes, Route, Switch, BrowserRouter, Link, useParams} from 'react-router-dom';
import About from '../pages/about';
import Contact from '../pages/contact';
import Gallery from '../pages/gallery';
import Awards from '../pages/awards';
import Home2 from "../pages/home2";
import Events from "../pages/events";
import Festivals from "../pages/festival";
import ActivityDetails from "../pages/ActivityDetails";
import Articles from "../pages/articles";
import ArticleDetail from "../pages/articleDetail";
import EventDetail from "../pages/eventDetail";
import GalleryCover from "../pages/gallery2";
import News from "../pages/news";
import Members from "../pages/members";
import FestivalList from "../pages/festivalsList";

const RedirectSite = (targetUrl) => {
    window.location.href = "/admin/manage.html";
    return <></>;
  };

const AppRoutes= ({displayLanguageCode, pageContent}) =>
{
return (
    <Routes>
        <Route exact path='/articles' element={<Articles displayLanguageCode={displayLanguageCode}  />} />
        <Route exact path='/article/:id?' element={<ArticleDetail displayLanguageCode={displayLanguageCode}  />} />
        <Route exact path='/home' element={<Home2 displayLanguageCode={displayLanguageCode} />} />
        <Route exact path='/festivals' element={<FestivalList displayLanguageCode={displayLanguageCode} />} />
        <Route exact path='/festival/:id?' element={<Festivals displayLanguageCode={displayLanguageCode} />} />
        <Route path='/gallery' element={<Gallery  pageContent={pageContent} displayLanguageCode={displayLanguageCode} />} />
        <Route path='/about' element={<About displayLanguageCode={displayLanguageCode} />} />
        <Route path='/contact' element={<Contact displayLanguageCode={displayLanguageCode} />} />
        <Route path='/awards' element={<Awards displayLanguageCode={displayLanguageCode} />} />
        <Route path='/admin/manage.html' element={<RedirectSite targetUrl="/admin/manage.html" />} />
        <Route exact path='/events' element={<Events displayLanguageCode={displayLanguageCode} />} />
        <Route exact path='/event/:id?' element={<EventDetail displayLanguageCode={displayLanguageCode} />} />
        <Route exact path='/news' element={<News displayLanguageCode={displayLanguageCode}/>}/>
        <Route exact path='/members' element={<Members displayLanguageCode={displayLanguageCode}/>}/>
        <Route path='*' element={<Home2 displayLanguageCode={displayLanguageCode} />} />
    </Routes>);
}

export default AppRoutes;