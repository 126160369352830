import React, {useState, useEffect } from "react";

import * as AppUtils from '../common/AppUtils.jsx'
import * as CultureInfo from '../common/CultureInfo.jsx'

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import useMediaQuery from '@mui/material/useMediaQuery';

// Model popup
import FullScreenDialog from '../components/FullScreenDialog.jsx'
import {AppLabels, MenuLabels} from '../common/CultureInfo.jsx'
import Grid2 from "@mui/material/Unstable_Grid2/Grid2.js";
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from "@mui/material";

export default function Awards({displayLanguageCode}) {
	
const supportedFilters = [{"title":"Government", "value": 'govt'}, {"title":"Private", "value": 'pvt'}];

const FormAwardsCount = (thePageContent)=>{
	var result = {"govt":0, "pvt": 0, "total":0};

	if(!thePageContent || !thePageContent.data || thePageContent.data.length <1)
	{
		AppUtils.TraceLog("No awards detected.");
		return result;
	}

	var govtCount = 0;
	var pvtCount = 0;
	for(var i =0; i < thePageContent.data.length;i++)
	{
		var item = thePageContent.data[i];
		if(item.tags.category.indexOf('govt')>-1)
			govtCount++;
		if(item.tags.category.indexOf('pvt')>-1)
			pvtCount++;
	}

	result.govt = govtCount;
	result.pvt = pvtCount;
	result.total = govtCount + pvtCount;

	return result;
}

const [filtersApplied, setFiltersApplied] = useState(['govt', 'pvt']);
const [pageContent, setPageContent] = useState(null);
const [formedPageContent, setFormedPageContent] = useState(()=> AppUtils.SortContentForGalleryView(pageContent, displayLanguageCode));
const [awardsCount, setAwardsCount] = useState(()=> FormAwardsCount(pageContent));

const lastViewedAwardStorageItemName = 'awardsJsonLastDownloaded'
const ONE_HOUR = 60 * 60 * 1000; /* ms */

var lastViewedAward = AppUtils.GetItemFromLocalStorage(lastViewedAwardStorageItemName, null);
var reloadCacheOptions = {cache:"no-store"};
if(AppUtils.IsDate(lastViewedAward)
	&& (new Date() - new Date(lastViewedAward)) < ONE_HOUR)
{
	AppUtils.TraceLog("Try loading the request from cache.");
	reloadCacheOptions = {};
}
else{
	AppUtils.TraceLog("Try loading from server.");
}
useEffect(() => {
	fetch('./data/awards.json', reloadCacheOptions)
		.then(response => response.json())
		.then(json => {
			setPageContent(json);
			setFormedPageContent(AppUtils.SortContentForGalleryView(json, displayLanguageCode));
			setAwardsCount(FormAwardsCount(json));
			AppUtils.TraceLog("App - Received AWARDS count: "+ (json.data && json.data.length > -1?json.data.length:0));
		})
		.catch(error => console.error(error));
	}, [displayLanguageCode]);



/* state information - required for the full page dialog popup - starts */
const [currentItemModalTitle, setCurrentItemModalTitle] = React.useState('');
const [modalDialogState, setModalDialogState] = React.useState(false);
const [currentModalContentItemObject, setCurrentModalContentItemObject] = React.useState('');
const [currentEventDate, setCurrentEventDate] = React.useState(null);
const [imagrSourceUrl, setImagrSourceUrl] =  React.useState(null);
const [currentContentId, setCurrentContentId] =  React.useState('');
/* state information - required for the full page dialog popup - ends */


const onCloseModalDialog = ()=>{setModalDialogState(false);}

const openModalPopup=(title, itemIndex, eventDate, imgSrc, dataId)=>{
	setCurrentItemModalTitle(title);
	setModalDialogState(true);
	setCurrentModalContentItemObject(formedPageContent[itemIndex].contentItemObject);
	setCurrentEventDate(eventDate);
	setImagrSourceUrl(imgSrc);
	setCurrentContentId(dataId);
}

const filterOnly2=(filterBy, shouldFilter) => {

	//AppUtils.TraceLog("Should filter: " + JSON.stringify( currentFilter));
	
	if(!pageContent || !pageContent.data || pageContent.data.length < 1)
	{
		AppUtils.TraceLog("Returning no awardpage content ");
		return;
	}

	var currentFilter = filtersApplied ?[...filtersApplied]:[];
	if(shouldFilter)
	{
		if(currentFilter.indexOf(filterBy) === -1)
		{
			currentFilter.push(filterBy);
		}
	}
	else
	{
		var currItemIndex = currentFilter.indexOf(filterBy) ;
		if(currItemIndex> -1)
		{
			currentFilter.splice(currItemIndex, 1);
		}
	}

	if(currentFilter.length === 2)
	{
		filterBy = '';
	}

	setFiltersApplied(currentFilter);

	if(filterBy === '')
	{
		setFormedPageContent(AppUtils.SortContentForGalleryView(pageContent, displayLanguageCode));
	}
	else if(currentFilter.length > 0)
	{
		var filteredContentTemp = pageContent.data.filter((item)=> {
			for(var fi in currentFilter)
			{
				if(item.tags["category"].indexOf(currentFilter[fi]) > -1)
				{
					return true;
				}
			}
			return false;	
		});
		setFormedPageContent(AppUtils.SortContentForGalleryView({"data": filteredContentTemp}, displayLanguageCode));
	}
	else
	{
		setFormedPageContent(AppUtils.SortContentForGalleryView({"data": []}, displayLanguageCode));
	}
}

const colsCount = useMediaQuery('(min-width:600px)')?2:1;


  return (<>
		<FormControl sx={{ m: 3 , display: 'flex'}} component="div" variant="standard">							
			<FormGroup row={true}>
			<FormLabel component="div" sx={{alignItems:"center", paddingTop:"7px", paddingRight:"7px"}}>
			{MenuLabels.awards[displayLanguageCode]} - {AppLabels.Labels.awardsBy[displayLanguageCode]}
			</FormLabel>
			{
					supportedFilters.map((item, index)=>
					{
						return <FormControlLabel										
								control={
									<Checkbox
										onChange={(eventObj) => filterOnly2(item.value, eventObj.target.checked)}
										value={item.value}
										name={item.title}
										defaultChecked
									/>
						}
						label={AppLabels.Labels[item.value][displayLanguageCode]+ " (" + awardsCount[item.value] +")" }
						/>
					})
			}
			</FormGroup>					
		</FormControl>
	{
		formedPageContent && formedPageContent.length < 1 && <p style={{color:"blue", backgroundColor:"lightBlue", fontSize:28}}>No content.
		{pageContent && pageContent.data && pageContent.data.length > 0 && <span>You removed all types of awards. Please select.</span>}
		</p>
	}

	<ImageList sx={{ width: "99%", height: "95%" }} gap={10} cols={colsCount}>
		{
		formedPageContent.map((item, itemIndex) => (
			<ImageListItem key={item.img} >
			<img
				src={`${item.img}?w=248&fit=crop&auto=format`}
				srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
				loading="lazy"
				sx={{alignSelf:"center"}}
				onClick={()=> openModalPopup(item.title, itemIndex, item.eventDate, item.img, item.dataId)}
			/>
			<ImageListItemBar
				title={item.title}
				subtitle={item.author}
				actionIcon={
					<Tooltip title={AppLabels.buttonLabels.moreDetails[displayLanguageCode]}>
					<IconButton onClick={()=> openModalPopup(item.title, itemIndex, item.eventDate, item.img, item.dataId)}
						sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
						aria-label={`info about ${item.title}`}
					>
						<InfoIcon  />
					</IconButton>
				</Tooltip>
				}
			/>
			</ImageListItem>
		))}
		</ImageList>
		<FullScreenDialog 
			title={currentItemModalTitle}
			contentId={currentContentId}
			displayState={modalDialogState}
			ModalDialogCloseHandler={onCloseModalDialog}
			contentItem={currentModalContentItemObject}
			eventDate={currentEventDate}
			displayLanguageCode={displayLanguageCode}
			imageSourceUrl={imagrSourceUrl}/>	
	</>
  );
}