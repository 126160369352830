import { Box, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import React, { useEffect, useState } from "react";
import PhotoAlbum from "react-photo-album";

import ContentViewer from "../components/ContentViewer"
import LanguageSelector from "../components/LanguageSelector";
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { BrowserRouter as Router, Routes, Route, Switch, BrowserRouter, Link, useParams, useNavigate} from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
//import Settings, { useSettings } from "../components/AlbumSettings"




//import './home.css'

import { styled } from '@mui/material/styles';
// import Box from '@mui/material/Box';
// import Paper from '@mui/material/Paper';

import * as CultureInfo from '../common/CultureInfo.jsx'
import { TraceLog } from "../common/AppUtils";
import AnnouncementViewer from '../components/AnnouncementViewer'
import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import * as AppUtils from "../common/AppUtils";
import MediaViewerSimpleImageSlider from "../components/MediaViewerImageSlider";
import MediaViewerCarousel from "../components/MediaViewerCarousel";
import MediaViewerCarouselMin from "../components/MediaViewerCarouselMin";
import AnnouncementViewer2 from "../components/AnnouncementViewer2";
import SearchPageTitleNavigatorSimple from "../components/SearchPageTitleNavigatorSimple";

import IsSmallScreen from '../common/AppUtils';
import FullScreenDialog from "../components/FullScreenDialog";

const News = ({ displayLanguageCode}) => {

	const [pageContent, setPageContent] = useState(null);
    const [isRequestInProgress, SetIsRequestInProgress] = useState(false);
	const [textToDisplay, SetTextToDisplay] = useState('');
    const [linkToShow, SetLinkToShow] = useState('');
    
    /* state information - required for the full page dialog popup - starts */
    const [currentItemModalTitle, setCurrentItemModalTitle] = React.useState('');
    const [modalDialogState, setModalDialogState] = React.useState(false);
    const [currentModalContentItemObject, setCurrentModalContentItemObject] = React.useState('');
    const [currentEventDate, setCurrentEventDate] = React.useState(null);
    const [imagrSourceUrl, setImagrSourceUrl] =  React.useState(null);
    const [currentContentId, setCurrentContentId] =  React.useState('');
    /* state information - required for the full page dialog popup - ends */

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {setOpen(false); };
	const textMaxLength = 100;
	const rootElementKey = 'article';
	const showLink = true;

	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: "95vw",        
		border: '2px solid #000',
		backgroundColor: "white",
		color: "black",
		boxShadow: 24,
		p: 2,
	};

    const onCloseModalDialog = ()=>{setModalDialogState(false);}

    const openModalPopup=(dataItem, eventObj)=>{
        setCurrentItemModalTitle(dataItem.eventName[displayLanguageCode]);
        setModalDialogState(true);
        setCurrentModalContentItemObject(dataItem.content);
        setCurrentEventDate(dataItem.eventDate);
        setImagrSourceUrl(eventObj.photo.src);
	    setCurrentContentId(dataItem.dataId);
    }

    const SortNewsItemDesc = (thePageContent)=>{
        if(!thePageContent || !thePageContent.data || thePageContent.data.length < 1)
        {
            return thePageContent;
        }
        for(var pc in thePageContent.data)
		{
			if(!thePageContent.data[pc].eventDateObject)
			{
				if(thePageContent.data[pc].eventDate)
				{
					thePageContent.data[pc].eventDateObject = new Date(thePageContent.data[pc].eventDate);
				}
				else
				{
					thePageContent.data[pc].eventDateObject = new Date('1900-01-01');
				}
			}
		}

        var sortedContents = thePageContent.data.sort((a, b) => b.eventDateObject - a.eventDateObject);
        return {data: sortedContents};
    }

    const PrepareNewsItems = (thePageContent)=>{
        var result = [];

        if(!thePageContent || !thePageContent.data || thePageContent.data.length < 1)
        {
            return result;
        }

        thePageContent.data.map((item, index)=>{
            var formedItem = {};
            formedItem.images = [];
            item.media.filter((imgElement)=> imgElement.type === 'image').
                map((onlyImgItem, index)=>{                    
                    formedItem.images.push(
                    {
                        src: onlyImgItem.url,
                        width: 100,
                        height: 100
                    })});
            formedItem.dataItem = item;
            result.push(formedItem);
        });

        return result;
    }


	useEffect(() => {
		SetIsRequestInProgress(true);
	  
		fetch('/data/news.json',{contentType:"text/json"})
		.then(response => response.json())
		.then(jsonResponse => {
			setPageContent(SortNewsItemDesc(jsonResponse));
			SetIsRequestInProgress(false);
		})
		.catch(error => {
			SetIsRequestInProgress(false);
			console.error(error);});

	}, []);

	var isSmallSizeScreen = IsSmallScreen(); 


	const articleLinkGenerateMethod = function(data)
	{
	  return '/news/' + data.dataId;
	}
	const onImageClick = function(item)
    {
        SetTextToDisplay(item.title[displayLanguageCode]);        
		SetLinkToShow(articleLinkGenerateMethod(item))
        handleOpen();
    }
	const contentAvailable = pageContent
                            && pageContent.data 
							&& pageContent.data.length > 0;

    const formedAlbums = PrepareNewsItems(pageContent); 

    const albumWidth = isSmallSizeScreen? "500px": "200px";

	return (<>
            {
			contentAvailable 
			?
                formedAlbums.map((item, index) =>
                {
                    return <Box  className="customAlbum" component={"div"}
                                key={"newsAlbumContainer"+ index} 
                                sx={{padding:"10px"}}
                                >

                            <Typography variant="h5" component="h5">{item.dataItem.eventName[displayLanguageCode]}</Typography>
                            <Typography variant="h7" component="h7">({item.dataItem.eventDate})</Typography>
                            <PhotoAlbum key={"newsAlbum"+ index} layout="columns" photos={item.images} columns={2} spacing={2} 
                                padding={10}
                                targetRowHeight={"100px"}
                                sx={{height:"100px"}}
                                style={{height:"100px"}}
                                onClick={(eventObj)=> openModalPopup(item.dataItem, eventObj)}
                            />
                    </Box>
                })                      
			:(isRequestInProgress? <CircularProgress color="success" /> : <p>No content.</p>)
        }
        <FullScreenDialog 
			title={currentItemModalTitle}
			contentId={currentContentId}
			displayState={modalDialogState}
			ModalDialogCloseHandler={onCloseModalDialog}
			contentItem={currentModalContentItemObject}
			eventDate={currentEventDate}
			displayLanguageCode={displayLanguageCode}
			imageSourceUrl={imagrSourceUrl}/>	
    </>
	);
};

export default News;