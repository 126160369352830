import React, { useEffect, useState } from "react";

import ContentViewer from "../components/ContentViewer"
import LanguageSelector from "../components/LanguageSelector";
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { BrowserRouter as Router, Routes, Route, Switch, BrowserRouter, Link, useParams, useNavigate } from 'react-router-dom';



import './home.css'

import { styled } from '@mui/material/styles';
// import Box from '@mui/material/Box';
// import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

import * as CultureInfo from '../common/CultureInfo.jsx'
import { TraceLog } from "../common/AppUtils";
import AnnouncementViewer from '../components/AnnouncementViewer'
import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import * as AppUtils from "../common/AppUtils";
import MediaViewerSimpleImageSlider from "../components/MediaViewerImageSlider";
import MediaViewerCarousel from "../components/MediaViewerCarousel";
import MediaViewerCarouselMin from "../components/MediaViewerCarouselMin";
import AnnouncementViewer2 from "../components/AnnouncementViewer2";
import SearchPageTitleNavigatorSimple from "../components/SearchPageTitleNavigatorSimple";
import IsSmallScreen from '../common/AppUtils';
import { Divider } from "@mui/material";
import ContentTitleDisplay from "../components/ContentTitleDisplay.jsx";
import MediaViewerImgVdo from "../components/MediaViewerImgVdo.jsx";

const Festivals = ({ displayLanguageCode }) => {

	const thisPageRouteWithoutSlash = '/festival';
	const getIndexFromContentAndRoute = function (jsonResponse) {
		var indexToView = jsonResponse && jsonResponse.data && jsonResponse.data.length > 0 ? 0 : -1;

		if (indexToView > -1 && homeRouteParams && homeRouteParams.id) {
			for (var si in jsonResponse.data) {
				if (jsonResponse.data[si].dataId === homeRouteParams.id) {
					console.log("Found a matching obj" + si);
					indexToView = parseInt(si);
					TraceLog("Matching index [" + indexToView + "] for route param [" + homeRouteParams.id + "]");
					break;
				}
			}
		}
		else {
			TraceLog("Skipped processing route params. index [" + indexToView + "]");
		}

		return indexToView;
	}

	const getCurrentPageUrlUsingIndex = function (jsonResponse, pageIndex) {
		//console.log(window.location.hostname);
		var pageUrl = AppUtils.BaseUrl() + thisPageRouteWithoutSlash;
		if (pageIndex > -1) {
			pageUrl = pageUrl + "/" + jsonResponse.data[pageIndex].dataId;
		}
		return pageUrl;
	}

	const getCurrentPageUrl = function (jsonResponse) {
		var pageIndex = getIndexFromContentAndRoute(jsonResponse);
		return getCurrentPageUrlUsingIndex(jsonResponse, pageIndex);
	}


	const [pageContent, setPageContent] = useState(null);
	const [currentIndex, setCurrentIndex] = useState(() => getIndexFromContentAndRoute(pageContent));
	const [currentPageUrl, setCurrentPageUrl] = useState(() => getCurrentPageUrl(pageContent));

	const navigate = useNavigate();
	const homeRouteParams = useParams();

	console.log("Route param =>" + JSON.stringify(homeRouteParams));

	useEffect(() => {
		fetch('/data/paddyfest.json', { contentType: "text/json" })
			.then(response => response.json())
			.then(jsonResponse => {
				setPageContent(jsonResponse);
				var pageIndex = getIndexFromContentAndRoute(jsonResponse);
				setCurrentPageUrl(getCurrentPageUrlUsingIndex(jsonResponse, pageIndex));
				setCurrentIndex(pageIndex);
			})
			.catch(error => console.error(error));
	}, [displayLanguageCode]);


	const SetNextItemIndexBy = (valueBy) => {
		if (pageContent && pageContent.data && pageContent.data.length > 0) {
			var nextIndex = currentIndex + parseInt(valueBy);
			if (nextIndex >= pageContent.data.length)
				nextIndex = 0;
			else if (nextIndex < 0)
				nextIndex = pageContent.data.length - 1;

			setCurrentIndex(nextIndex);
			var nextItemId = pageContent.data[nextIndex].dataId;
			navigate(thisPageRouteWithoutSlash + '/' + nextItemId, { replace: false });

		}
		console.log('Current index will be ' + nextIndex);
	}

	const OnPageTitleChanged = (pageChangedEventValue) => {
		console.log('Page title changed: ' + JSON.stringify(pageChangedEventValue));
		if (pageContent && pageContent.data && pageContent.data.length > 0) {
			var selectedPageIndex = parseInt(pageChangedEventValue.value);

			if (selectedPageIndex < pageContent.data.length
				&& selectedPageIndex > -1) {
				setCurrentIndex(selectedPageIndex);
				var nextItemId = pageContent.data[selectedPageIndex].dataId;
				navigate(thisPageRouteWithoutSlash + '/' + nextItemId, { replace: false });
			}
		}
	}

	var nextLabelText = 'Next';
	var prevLabelText = 'Prev';

	var isSmallSizeScreen = IsSmallScreen();


	if (CultureInfo.AppLabels && CultureInfo.AppLabels.buttonLabels) {
		prevLabelText = CultureInfo.AppLabels.buttonLabels.prev[displayLanguageCode];
		nextLabelText = CultureInfo.AppLabels.buttonLabels.next[displayLanguageCode];
	}

	var announcements = pageContent && pageContent.announcements ? pageContent.announcements : [];

	TraceLog("Home announcements => " + JSON.stringify(announcements));

	const contentAvailable = currentIndex > -1
		&& pageContent
		&& pageContent.data
		&& pageContent.data.length > 0
		&& currentIndex < pageContent.data.length;

	return (
		<>
			{/* <Grid container spacing={2}>*/}

			{/* <Grid xs={12} md={12}> */}
			{
				contentAvailable
					? pageContent.data[currentIndex]
						? <div className="mainDisplayContainer">
							<fieldset style={{ padding: "0px", borderColor: "grey transparent transparent transparent" }}>
								<legend>{CultureInfo.AppLabels.page["festivals"][displayLanguageCode]}</legend>
								<Grid2 container sx={{ display: "flex" }} lg={12} md={12} sm={12} xs={12} spacing={2}>
									<Grid2 item lg={1} md={1} sm={1} xs={2}>
										<Tooltip title={CultureInfo.AppLabels.tooltips.next[displayLanguageCode]}>
											{/* <Button variant="contained" color="primary"  sx={{ height: 40 }}>
											{nextLabelText}										
										</Button> */}
											<NavigateBefore className="applinks" key={"prevArticleButton"} onClick={() => { SetNextItemIndexBy(-1); }} />
										</Tooltip>
									</Grid2>
									<Grid2 item lg={1} md={1} sm={1} xs={2}>
										<span className='floatRight'>
											<Tooltip title={CultureInfo.AppLabels.tooltips.prev[displayLanguageCode]}>
												{/* <Button variant="contained" color="primary" onClick={()=> {SetNextItemIndexBy(-1);}} sx={{ height: 40 }}>
											{prevLabelText}
											</Button> */}
												<NavigateNext key={"nextArticleButton"} className="applinks"
													sx={{ float: "right", alignSelf: "end" }} onClick={() => { SetNextItemIndexBy(1); }} />
											</Tooltip>
										</span>
									</Grid2>
									<Grid2 item lg={10} md={10} sm={10} xs={8}>
										<SearchPageTitleNavigatorSimple
											key={"pageTitleSearchDDL"}
											displayLanguageCode={displayLanguageCode}
											pageContentItems={pageContent}
											pageIndex={currentIndex}
											OnPageTitleChanged={OnPageTitleChanged}
										/>
										<AnnouncementViewer2
											key={"announcementViewerContainer"}
											announcements={announcements}
											displayLanguageCode={displayLanguageCode} />
									</Grid2>
								</Grid2>
							</fieldset>
							<Grid2 container lg={12} md={12} sm={12} xs={10} >
								<ContentTitleDisplay
									subtitle={pageContent.data[currentIndex].eventName[displayLanguageCode]}
									currentItemIndex={currentIndex}
									totalItemCount={pageContent.data.length}
									displayLanguageCode={displayLanguageCode}
								/>

								{/* <div className="mediaContainer" style={{clear:"both"}}> */}
								{/* <MediaViewerSimpleImageSlider
										key="mediaViewerSimpleImageSlider" 
										content={pageContent.data[currentIndex].media} 
										index={currentIndex}
										customClassName="" /> */}


								{/* <MediaViewerCarousel key={"mediaViewerCarousel"}
										content={pageContent.data[currentIndex].media} 
										index={currentIndex}
										customClassName=""></MediaViewerCarousel> */}

								{/* <MediaViewerCarouselMin key={"mediaViewerCarouselMin"}
										displayLanguageCode={displayLanguageCode}
										content={pageContent.data[currentIndex].media} 
										index={0}
										customClassName=""/> */}

								<MediaViewerImgVdo
									displayLanguageCode={displayLanguageCode}
									pageContent={pageContent.data[currentIndex]}
									index={0}
									customClassName=""></MediaViewerImgVdo>

								{/* </div> */}
							</Grid2>
							<Grid2 container lg={12} md={12} sm={12} xs={10} >
								<ContentViewer
									customClassName="contentContainer"
									contentItem={pageContent.data[currentIndex].content}
									index={currentIndex}
									displayLanguageCode={displayLanguageCode}
									eventDate={pageContent.data[currentIndex].eventDate}
								></ContentViewer>
							</Grid2>

							<div className="fb-like"
								data-href={currentPageUrl}
								data-width="50"
								data-layout=""
								data-action=""
								data-size=""
								data-share={true}></div>



						</div>
						: <p>You viewed all content.</p>
					: <p>No content.</p>
			}



			{/* </Grid> */}
			{/*   </Grid>*/}
		</>
	);
};

export default Festivals;
