import React, { useEffect, useState } from "react";

import ContentViewer from "./ContentViewer"
import LanguageSelector from "./LanguageSelector";
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { BrowserRouter as Router, Routes, Route, Switch, BrowserRouter, Link, useParams, useNavigate} from 'react-router-dom';



import '../pages/home2.css'


import { styled } from '@mui/material/styles';
// import Box from '@mui/material/Box';
// import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

import * as CultureInfo from '../common/CultureInfo.jsx'
import { TraceLog } from "../common/AppUtils";
import AnnouncementViewer from './AnnouncementViewer'
import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import * as AppUtils from "../common/AppUtils";
import MediaViewerSimpleImageSlider from "./MediaViewerImageSlider";
import MediaViewerCarousel from "./MediaViewerCarousel";
import MediaViewerCarouselMin from "./MediaViewerCarouselMin";
import AnnouncementViewer2 from "./AnnouncementViewer2";
import SearchPageTitleNavigatorSimple from "./SearchPageTitleNavigatorSimple";
import IsSmallScreen from '../common/AppUtils';
import { Divider } from "@mui/material";
import ContentViewerDynamic from "./ContentViewerDynamic";

const VisionAndMission = ({ displayLanguageCode='en'}) => {

    const pageContent = {
        "dataId": "visionAndMissionPageContent",
        "media": [
        ],
        "content": {
          "en": {
            "type": "relativePathUrl",
            "content": "/data/static/visionAndMission/en/content.html"
          },
          "ta": {
            "type": "relativePathUrl",
            "content": "/data/static/visionAndMission/ta/content.html"
          }
        },
        "eventDate": "2023-09-16T00:00:00Z",
        "eventPlace": {
          "ta": "நெல் ஜெயராமன் பாரம்பரிய நெல் பாதுகாப்பு மையம், ஆதிரெங்கம்",
          "en": "Nel Jayaraman Traditional Rice Conservation Centre, Adirengam "
        },
        "eventName": {
          "en": "Our vision and mission",
          "ta": "எங்கள் குறிக்கோள் மற்றும் பணி"
        }
      };      

return (<>
        <div className="container">
            <h2>{pageContent.eventName[displayLanguageCode]}</h2>
            <ContentViewerDynamic
                            contentUrl={pageContent.content[displayLanguageCode].content}
                            index ={0}
                            eventDate={pageContent.eventDate}
                            displayLanguageCode={displayLanguageCode}
                            customClassName="text"
                            customClassNameForContent="listItemCss"
                            showReadMore={true}
                            readMoreMinCharacters={500} />
        </div>
        </>
    )
}

export default VisionAndMission;
