import React, { useEffect, useState } from "react";
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import { CircularProgress } from "@mui/material";
import { MemberPageLabels } from "../common/CultureInfo";

const rows = [
    { id: 1, nameCol: 'Hello', placeCol: 'World', districtCol: 'Thiruvarur' },
    { id: 2, nameCol: 'Hello', placeCol: 'World', districtCol: 'Thiruvarur' },
    { id: 3, nameCol: 'Hello', placeCol: 'World', districtCol: 'Thiruvarur' }
];
const columns = [
    { field: 'nameCol', headerName: 'Name', width: 150 },
    { field: 'placeCol', headerName: 'Place', width: 150 },
    { field: 'districtCol', headerName: 'District', width: 150 }
];

export default function Members({ displayLanguageCode }) {

    const prepareRows = function (languageCode, memberData) {
        var result = [];

        if (!memberData || !memberData.data || !memberData.data.length) {
            return result;
        }

        for (var i in memberData.data) {
            var currMember = memberData.data[i];
            var obj = {};
            obj.id = currMember.id;
            obj.nameCol = currMember.name[languageCode];
            obj.districtCol = currMember.memberDistrict[languageCode];
            obj.placeCol = currMember.memberPlace[languageCode];
            obj.joinedDateCol = currMember.joinedDate;

            result.push(obj);
        }

        var colResult = 
        [
            { field: 'nameCol', headerName: MemberPageLabels.name[languageCode], width: 200 },
            { field: 'placeCol', headerName: MemberPageLabels.place[languageCode] , width: 200},
            { field: 'districtCol', headerName: MemberPageLabels.district[languageCode], width: 200}
        ];


        return { rows: result, columns: colResult};
    }

    const [pageContent, setPageContent] = useState({rows:[], columns:[]});
    const [isRequestInProgress, SetIsRequestInProgress] = useState(false);


    useEffect(() => {
        SetIsRequestInProgress(true);

        fetch('/data/members.json', { contentType: "text/json" })
            .then(response => response.json())
            .then(jsonResponse => {
                setPageContent(prepareRows(displayLanguageCode, jsonResponse));
                SetIsRequestInProgress(false);
            })
            .catch(error => {
                SetIsRequestInProgress(false);
                console.error(error);
            });

    }, [displayLanguageCode]);

    return (
        <>
            <div style={{ height: "80vh", width: "95vw"}}>
                <p>{MemberPageLabels.membersSubtitle[displayLanguageCode]}</p>
                <DataGrid 
                    rows={pageContent.rows}
                    columns={pageContent.columns} 
                    showColumnVerticalBorder={false}
                    sx={{minHeight:"10vh", height:"65vh"}}/>
            </div>
            {
                isRequestInProgress ? <CircularProgress color="success" /> : <></>
            }
        </>
    );
}
