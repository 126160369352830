import React from "react";
import { useState, useEffect } from "react";
import IsSmallScreen, { TraceLog } from "../common/AppUtils";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import Modal from '@mui/material/Modal';
import { orange } from "@mui/material/colors";
import { AppLabels } from "../common/CultureInfo";
import { GetVideoIdFromUrl, IsYouTubeVideo } from '../common/AppUtils';
import YouTube from "react-youtube";


function TileDisplay({
    displayLanguageCode,
    tileItem,
    index,
    onTileImageClicked,
    showLink = false,
    linkGenMethod = null,
    textMaxLength = 50,
    tileWidth = "25vw",
    tileHeight = "20vh",
    rootElementKey = '',
    imageClassName = ''
}) {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "95vw",
        border: '2px solid #000',
        backgroundColor: "white",
        color: "black",
        boxShadow: 24,
        p: 4,
    };

    const onImageClick = function (item) {
        if (onTileImageClicked)
            onTileImageClicked(item);
    }

    const maxCharsAllowed = textMaxLength;
    const getTextToDisplay = function (theText) {
        var endIndex = theText.indexOf(' ', maxCharsAllowed);
        if (endIndex < 0) {
            endIndex = theText.indexOf('.', maxCharsAllowed);
        }
        if (endIndex < 0) {
            endIndex = maxCharsAllowed;
        }
        return theText.length < maxCharsAllowed ? theText : theText.slice(0, endIndex);
    }

    TraceLog("Tile: " + JSON.stringify(tileItem));

    const isVideo = tileItem.mediaType === "video";
    const isYouTubeVideo = IsYouTubeVideo(tileItem.imgUrl);
    const youtubeVideoId = isYouTubeVideo ? GetVideoIdFromUrl(tileItem.imgUrl) : '';
    const youtubeVideoOptions = {
        height: "100%",
        width: "100%",
        playerVars: {
            autoplay: 0,
        },
    };
    const onPlayerReady = (event) => {
        const player = event.target;
        player.pauseVideo();
    };


    return (<>
        {tileItem &&
            <span style={{ display: "inline-block", width: tileWidth, padding: "10px" }}>
                <Tooltip key={"tt" + rootElementKey + index}
                    title={tileItem.title[displayLanguageCode]}>
                    {
                        <div>
                            {
                                tileItem.dataItem.eventDateDisplayString && tileItem.dataItem.eventDateDisplayString.length
                                    ? <div style={{ position: "relative", bottom: "-25px", left: "20px" }}>
                                        {tileItem.dataItem.eventDateDisplayString}
                                    </div>
                                    : <></>
                            }
                            {

                                isVideo && isYouTubeVideo &&
                                <Box
                                    style={{ padding: "1px 1vw" }}
                                    width={tileWidth}
                                    height={tileHeight}
                                    key={"imgWrap" + rootElementKey + index}
                                    component="div"
                                    className={imageClassName}
                                    alt="Image."
                                    src={tileItem.imgUrl}
                                    onClick={() => { onImageClick(tileItem) }}>
                                    <YouTube
                                        key={"youtubeVdo" + rootElementKey + index}
                                        videoId={youtubeVideoId}
                                        opts={youtubeVideoOptions}
                                        onReady={onPlayerReady}
                                        style={{ width: "100%", height: "100%" }}
                                    />
                                </Box>
                            }
                            {

                                isVideo && isYouTubeVideo == false &&
                                <Box
                                    style={{ padding: "1px 1vw" }}
                                    width={tileWidth}
                                    height={tileHeight}
                                    key={"imgWrap" + rootElementKey + index}
                                    component="div"
                                    className={imageClassName}
                                    alt="Image."
                                    src={tileItem.imgUrl}
                                    onClick={() => { onImageClick(tileItem) }}>
                                    <video
                                        key={"vdo" + rootElementKey + index}
                                        width={"100%"} height={"100%"} autoPlay={false} controls={false}
                                        src={tileItem.imgUrl} >
                                    </video>
                                </Box>
                            }

                            {(isVideo == false) && <Box
                                style={{ padding: "1px 1vw" }}
                                width={tileWidth}
                                height={tileHeight}
                                key={"imgWrap" + rootElementKey + index}
                                component="img"
                                className={imageClassName}
                                alt="Image."
                                src={tileItem.imgUrl}
                                onClick={() => { onImageClick(tileItem) }}
                            />
                            }


                        </div>
                    }

                </Tooltip>
                <p style={{ padding: "1vw", wordWrap: "break-word", wordBreak: "break-word", textOverflow: "ellipsis" }}>
                    {
                        showLink && <Button
                            key={"link" + rootElementKey + index}
                            component={Link}
                            underline={"hover"}
                            to={linkGenMethod(tileItem)}>
                            {getTextToDisplay(tileItem.title[displayLanguageCode])}
                        </Button  >
                    }
                    {
                        (showLink === false) && getTextToDisplay(tileItem.title[displayLanguageCode])
                    }
                </p>
            </span>
        }
    </>
    )
}

export default TileDisplay; 