import React from "react";
import ReactDOM from "react-dom";
import Select, { components } from "react-select";
import { TraceLog } from "../common/AppUtils";
import { AppLabels } from "../common/CultureInfo";
import { useTheme, ThemeProvider, createTheme } from '@mui/material/styles';

export default function SearchPageTitleNavigatorSimple(
    {pageContentItems, pageIndex, OnPageTitleChanged,
    displayLanguageCode='en'}
    )
{

  const theme = useTheme();


    const prepareSearchablePageList = function()
    {
        var ddlOptions = [];

        if(pageContentItems && pageContentItems.data)
        {
            for(var i in pageContentItems.data)
            {
                ddlOptions.push(
                    {
                        value: i,
                        label: pageContentItems.data[i].eventName[displayLanguageCode]
                    });
            }
        }
        return ddlOptions;

    }

const options = prepareSearchablePageList();

const onSelectedOptionChanged = function(selectedObject)
{
    TraceLog(JSON.stringify(selectedObject));

    if(selectedObject)
    {
        OnPageTitleChanged(selectedObject);
    }
}

const Option = props => {
    return (
      <components.Option {...props}>
        <div>{props.data.label.length > 100 ? (props.data.label.substring(0,100) + ".."): props.data.label}
        </div>
      </components.Option>
    );
  };

var languageUsed = displayLanguageCode? displayLanguageCode: 'en';
var currentTheme = theme.palette.mode;
var ddlTextColor = currentTheme === 'dark'? "white": "black";
var ddlBgColor = currentTheme === 'dark'? "dark": "white";

const dot = (color = 'transparent') => ({
    alignItems: 'center',
    display: 'flex',
  });

return (
        <div key={"pageSearchDDLContainer"} className={languageUsed} 
            style={{display:"inline-block", minWidth:"55vw", maxWidth:"55vw"}}>
            <Select
                sx={{
                    minWidth:'55vw',
                    maxWidth:"55vw"
                }}
                styles={{
                    control: (styles) => ({ ...styles, backgroundColor: theme.palette.background.default, color: theme.palette.text.primary }),
                    option: (styles) => ({ ...styles, backgroundColor: theme.palette.background.default, color: theme.palette.text.primary }),
                    input: (styles) => ({ ...styles, backgroundColor: "transparent", color: theme.palette.text.primary }),               
                    placeholder: (styles) => ({ ...styles, backgroundColor: theme.palette.background.default, color: theme.palette.text.secondary  }),
                    singleValue: (styles) => ({ ...styles, backgroundColor: theme.palette.background.default, color: theme.palette.text.secondary })
                }}
                options={options}
                components={{ Option }}
                isClearable={true}
                placeholder={AppLabels.eventNameSearch[displayLanguageCode]}
                onChange={onSelectedOptionChanged} />
        </div> 
    );
}