import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';

import * as AppUtils from '../common/AppUtils'
import { MenuLabels } from '../common/CultureInfo';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import LanguageSelector2 from './LanguageSelector2';
import * as CultureInfo from '../common/CultureInfo'
import { IconButton, Tooltip, Typography } from '@mui/material';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import ThemeSelector from './ThemeSelector';

export default function MenuBarSide({displayLanguageCode='en', themeModeName, OnThemeChanged, OnSelectedLanguageIndexChanged}) {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const navigate = useNavigate();

  const onMenuItemClick = (pageItem)=>{
    navigate(pageItem.linkTo, {replace: false});
  }


  var selectedLanguageCode = displayLanguageCode?displayLanguageCode:'en';
    var pages = AppUtils.GetAllPages();

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      key={"SidebarAppDrawerBox"}
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
        <List key="sidebarHypherLinks">
          {
            pages.map((p, index)=>{ return <>                    
                    <Typography key={"SidebarLinkItem"+ p.linkName + index}>
                      <Button key={"sidebarLinkButton" + p.linkName + index} component={Link}  to={p.linkTo}>
                        {MenuLabels[p.linkName][selectedLanguageCode]}                    
                      </Button  >
                    </Typography>
                    </>
                })
          }
        </List>
        <Divider key={"SidebarLinkAndSettingsDivider"}/>
        <Typography key={"SidebarSettingsTypography"}>
          {CultureInfo.AppLabels.Labels.settings[displayLanguageCode]}
        </Typography>
        <List key={"SidebarSettingsSectionLanguage"}>
            <LanguageSelector2 key={"SidebarLanguageSelector"} customClassName='languageSelectorStyle'
                          languages={CultureInfo.DisplayLanguageList}
                          selectedLanguageCode={displayLanguageCode} 
                          OnSelectedIndexChanged={OnSelectedLanguageIndexChanged}/>
        </List>
        <List key={"SidebarSettingsSectionTheme"}>          
           <ThemeSelector 
              displayLanguageCode={displayLanguageCode}
              themeModeName={themeModeName}
              OnToggleThemeCallback={()=>{OnThemeChanged();}}/>
        </List>
        <Divider key={"SidebarSettingsEndDivider"}/>
      
      {/* <List>
        {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List> */}

    </Box>
  );

  const displayLocation = 'left'; 

  return (
    <div>
        <MenuIcon key={"menuButtonSmallScreen"}
            className="applinks"
            sx={{cursor:"cursor", alignSelf:'flex-end', height:"10vmin"}} 
            onClick={toggleDrawer(displayLocation, true)}/>
        <Drawer
            key={"drawerSmallScreen"}
            anchor={displayLocation}
            open={state[displayLocation]}
            onClose={toggleDrawer(displayLocation, false)}
          >
            {list(displayLocation)}
        </Drawer>
      {/* {['left', 'right', 'top', 'bottom'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))} */}
    </div>
  );
}
