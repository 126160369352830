import Dropdown from 'react-dropdown';
import { Button, Tooltip, Typography } from '@mui/material';
import 'react-dropdown/style.css';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { AppLabels } from '../common/CultureInfo';
import * as CultureInfo from '../common/CultureInfo'

function LanguageSelector2({languages, selectedLanguageCode="en", OnSelectedIndexChanged, parentKey='', customClassName='', hideLabel=false})
{
    var labelText = hideLabel? '':CultureInfo.AppLabels.Labels.language[selectedLanguageCode];
    var currentItem = languages.find(a=> a.value === selectedLanguageCode);
    return (
        // <FormControl key={"languageSelectorForm"} sx={{ m: 1, minWidth: 10 }} size="small">
            <span>
                {labelText}
                <Button key={parentKey+"languageSelectorSurroundingDummyButton"} size='small'>
                    <Select
                        key={parentKey+"languageSelectorDropdown"}
                        value={currentItem.value}
                        sx={{fontSize:"smaller"}}
                        onChange={(selectedEventObj)=> OnSelectedIndexChanged(selectedEventObj.target.value)}
                        inputProps={{ 'aria-label': 'demo-simple-select-helper-label' }}>
                            {languages.map((l, i)=>{
                                return <MenuItem key={parentKey+"languageOption"+i} value={l.value}>                        
                                        {l.label}
                                    </MenuItem>
                            })}
                    </Select>
                </Button>
            </span>
        //</FormControl>
    );
    
}

export default LanguageSelector2;