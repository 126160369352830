import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import InfoIcon from '@mui/icons-material/Info';
import ContentViewer from '../components/ContentViewer'
import { AppLabels } from '../common/CultureInfo';
import { Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { Component } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import CropFreeIcon from '@mui/icons-material/CropFree';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({contentId, title, ModalDialogCloseHandler, contentItem, eventDate, displayLanguageCode, displayState=false,imageSourceUrl=''}) {

  const handleClose = () => {
    ModalDialogCloseHandler();
  };

  const [imageWidthPercentage, setImageWidthPercentage] = React.useState(100);
  const [imageHeightPercentage, setImageHeightPercentage] = React.useState(100);
  const [copyStatus, setCopyStatus] = React.useState('');

  const maxImageSizePercentage = 200;
  const minImageSizePercentage = 25;

  const handleImageClick=(eventObj)=>{}

  const copyContentId = async () => {
    await navigator.clipboard.writeText(`${contentId}`);
    setCopyStatus('Copied !');
    setTimeout(function(){
      setCopyStatus('');
    }.bind(this),5000);  // wait 5 seconds, then reset to false
  }

  return (
    <div>
      <Dialog
        key={"dialogItem1"}
        fullScreen
        // open={open}
        open={displayState}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar key={"appBar1"} sx={{ position: 'relative' }}>
          <Toolbar>            
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div" className={displayLanguageCode}>
              {title}
            </Typography>
            
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div style={{paddingLeft:"5px", paddingRight:"5px"}} >
          {imageSourceUrl && 
          <>
            <TransformWrapper centerOnInit={true} >
              {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <React.Fragment>
                  <div className="tools">
                    <Tooltip title="Zoom in">
                      <ZoomInIcon onClick={() => zoomIn()}></ZoomInIcon>
                    </Tooltip>
                    <Tooltip title="Zoom out">
                      <ZoomOutIcon onClick={() => zoomOut()}></ZoomOutIcon>
                    </Tooltip>
                    <Tooltip title="Reset zoom">
                      <CropFreeIcon onClick={() => resetTransform()}></CropFreeIcon>
                    </Tooltip>
                  </div>
                  <TransformComponent >
                    <img 
                      style={{maxWidth:"90vw", overflow:"scroll"}}
                      key={"imageItem"}                     
                      src={`${imageSourceUrl}?w=100%&auto=format`}
                      alt={title} />
                  </TransformComponent>
                </React.Fragment>
              )}
            </TransformWrapper>
            </>
          }
          
          <ContentViewer 
            customClassName="contentContainer"
            contentItem = {contentItem} 
            index={0} 
            displayLanguageCode={displayLanguageCode}
            eventDate={eventDate}						
            ></ContentViewer>

          {
            contentId &&
            <div  style={{clear:"both", display:"flex", paddingTop:"20px"}}>
              <span style={{paddingRight:"10px"}}>Content ID</span>
              <input style={{maxWidth: "80%", width:"auto"}} 
                type="text" 
                key="contentIdInFullScreenDialog"
                id="contentIdInFullScreenDialog"
                value={contentId}>
              </input>
              <ContentCopyIcon
                onClick={copyContentId}></ContentCopyIcon>
              <span>
                {
                  copyStatus
                }
              </span>
            </div>
          }
        </div>
      </Dialog>
    </div>
  );
}