import React, { useEffect, useState } from "react";

import ContentViewer from "../components/ContentViewer"
import LanguageSelector from "../components/LanguageSelector";
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { BrowserRouter as Router, Routes, Route, Switch, BrowserRouter, Link, useParams, useNavigate} from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';



import './home.css'

import { styled } from '@mui/material/styles';
// import Box from '@mui/material/Box';
// import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

import * as CultureInfo from '../common/CultureInfo.jsx'
import { TraceLog } from "../common/AppUtils";
import AnnouncementViewer from '../components/AnnouncementViewer'
import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import * as AppUtils from "../common/AppUtils";
import MediaViewerSimpleImageSlider from "../components/MediaViewerImageSlider";
import MediaViewerCarousel from "../components/MediaViewerCarousel";
import MediaViewerCarouselMin from "../components/MediaViewerCarouselMin";
import AnnouncementViewer2 from "../components/AnnouncementViewer2";
import SearchPageTitleNavigatorSimple from "../components/SearchPageTitleNavigatorSimple";
import IsSmallScreen from '../common/AppUtils';
import { Divider, Modal, Typography } from "@mui/material";
import TileDisplay from "../components/TileDisplay";

const FestivalList = ({ displayLanguageCode}) => {

	const [pageContent, setPageContent] = useState(null);
    const [isRequestInProgress, SetIsRequestInProgress] = useState(false);
	const [textToDisplay, SetTextToDisplay] = useState('');
    const [linkToShow, SetLinkToShow] = useState('');

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {setOpen(false); };
	const textMaxLength = 100;
	const rootElementKey = 'article';
	const showLink = true;

	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: "95vw",        
		border: '2px solid #000',
		backgroundColor: "white",
		color: "black",
		boxShadow: 24,
		p: 2,
	};


	useEffect(() => {
		SetIsRequestInProgress(true);
	  
		fetch('/data/paddyfest.json',{contentType:"text/json"})
		.then(response => response.json())
		.then(jsonResponse => {
			setPageContent(AppUtils.FormTileData(jsonResponse, false));
			SetIsRequestInProgress(false);
		})
		.catch(error => {
			SetIsRequestInProgress(false);
			console.error(error);});

	}, []);

	var isSmallSizeScreen = IsSmallScreen(); 


	const articleLinkGenerateMethod = function(data)
	{
	  return '/festival/' + data.dataId;
	}
	const onImageClick = function(item)
    {
        SetTextToDisplay(item.title[displayLanguageCode]);        
		SetLinkToShow(articleLinkGenerateMethod(item))
        handleOpen();
    }

	const contentAvailable = pageContent 
							&& pageContent.length > 0;

	return (
		<>
		{
			contentAvailable 
			? <>
			<h2>{CultureInfo.AppLabels.page["festivals"][displayLanguageCode]}</h2>
			<Grid2 container sx={{display:"flex"}} lg={12} md={12} sm={12} xs={12} spacing={2}>
				{
					pageContent.map((item, index) =>{
					return <TileDisplay key={"tile"+ rootElementKey + index} 
						displayLanguageCode={displayLanguageCode}
						tileItem={item}
						index={index}
						onTileImageClicked={onImageClick}
						showLink={showLink}
						linkGenMethod={articleLinkGenerateMethod}
						textMaxLength={textMaxLength} 
						rootElementKey={rootElementKey}
						tileHeight={"40vh"}
						tileWidth={isSmallSizeScreen? "80vw": "40vw"}
						imageClassName={"galaryImage"}
						/>
					})
				}				
				</Grid2>
				<Modal
					key={"helpTextModalPopup"+rootElementKey}
					open={open}
					onClose={handleClose}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
					width="80vw"
					>
					<Box key={"surroundingBoxTextDisplay"} sx={style}
						width={"30vw"}
						minHeight={"15vw"}>

						<Box key={"closeButtonInModalPopup"}
							style={{padding:"1px 1vw", textDecoration:"underline dotted", fontStyle:"italic", float:"right"}}                            
							component={Link}                        
							onClick={()=>{handleClose()}}
						>X</Box>

					<Typography id="modal-modal-description" sx={{ mt: 2 }}>
						{textToDisplay}
					</Typography>
					{
						showLink && <Button                                               
							key={"workSampleLinkInPopup"}
							component={Link}
							underline={"hover"}
							style={{padding:"1px 1vw", textDecoration:"underline dotted", color:"darkgreen", fontStyle:"italic"}}
							to={linkToShow}>
							{CultureInfo.AppLabels.buttonLabels.moreDetails[displayLanguageCode]}
						</Button  >
					}
					</Box>
				</Modal>
				</>
			:(isRequestInProgress? <CircularProgress color="success" /> : <p>No content.</p>)
		}
	</>
	);
};

export default FestivalList;
