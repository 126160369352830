import React, {useState, useEffect } from "react";

import * as AppUtils from '../common/AppUtils.jsx'
import * as CultureInfo from '../common/CultureInfo.jsx'

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import useMediaQuery from '@mui/material/useMediaQuery';

// Model popup
import FullScreenDialog from '../components/FullScreenDialog.jsx'
import {AppLabels} from '../common/CultureInfo.jsx'
import SimpleImageSlider from "react-simple-image-slider";

export default function Gallery({pageContent, displayLanguageCode}) {

	// const [pageContent, setPageContent] = useState(null);
	const [formedPageContent, setFormedPageContent] = useState(()=> AppUtils.SortContentForGalleryView(pageContent, displayLanguageCode));
	

/* state information - required for the full page dialog popup - starts */
	const [currentItemModalTitle, setCurrentItemModalTitle] = React.useState('');
	const [modalDialogState, setModalDialogState] = React.useState(false);
	const [currentModalContentItemObject, setCurrentModalContentItemObject] = React.useState('');
	const [currentEventDate, setCurrentEventDate] = React.useState(null);
	const [imagrSourceUrl, setImagrSourceUrl] =  React.useState(null);
	const [currentContentId, setCurrentContentId] =  React.useState('');
	
/* state information - required for the full page dialog popup - ends */


	const onCloseModalDialog = ()=>{setModalDialogState(false);}

const openModalPopup=(title, itemIndex, eventDate, imgSrc, dataId)=>{
		setCurrentItemModalTitle(title);
		setModalDialogState(!modalDialogState);
		setCurrentModalContentItemObject(formedPageContent[itemIndex].contentItemObject);
		setCurrentEventDate(eventDate);
		setImagrSourceUrl(imgSrc);
		setCurrentContentId(dataId);
	}

	const colsCount = useMediaQuery('(min-width:600px)')?2:1;

  return (	<>
    <ImageList  cols={colsCount} variant="masonry" gap={5} >
      <ImageListItem key="imageListItemSubheader" cols={colsCount}>
        <ListSubheader component="div">{CultureInfo.MenuLabels.gallery[displayLanguageCode]}</ListSubheader>
      </ImageListItem>
      {
	  formedPageContent.map((item, itemIndex) => (
        <ImageListItem key={"imglistItem"+item.img+"-"+itemIndex}>
          <img
            src={`${item.img}?w=248&fit=crop&auto=format`}
            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
            //alt={item.title}
            loading="lazy"
				sx={{alignSelf:"center"}}
				onClick={()=> openModalPopup(item.title, itemIndex, item.eventDate, item.img, item.dataId)}
          />
          <ImageListItemBar
		  	key={"imglistItemBar"+item.img+"-"+itemIndex}
				title={item.title}
				subtitle={item.author}
				actionIcon={
					<Tooltip title={AppLabels.buttonLabels.moreDetails[displayLanguageCode]}>
						<IconButton onClick={()=> openModalPopup(item.title, itemIndex, item.eventDate, item.img, item.dataId)}
						sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
						aria-label={`info about ${item.title}`}><InfoIcon  />
					</IconButton>
				</Tooltip>
				}
          />
        </ImageListItem>
      ))}
    </ImageList>
	<FullScreenDialog key={"fullsccreenDialogInGalleryPage"}
		contentId={currentContentId}
		title={currentItemModalTitle}
		displayState={modalDialogState}
		ModalDialogCloseHandler={onCloseModalDialog}
		contentItem={currentModalContentItemObject}
		eventDate={currentEventDate}
		displayLanguageCode={displayLanguageCode}
		imageSourceUrl={imagrSourceUrl}/>	
	</>
  );
}