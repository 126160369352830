import { useState, useEffect } from "react";
//import ReactDOM from 'react-dom';
//import { createRoot } from 'react-dom/client';
import {TraceLog, ParseContentIntoParagraphs} from '../common/AppUtils'
import ContentViewerDynamic from './ContentViewerDynamic.jsx'
import {AppLabels} from '../common/CultureInfo'


function ContentViewer({contentItem, index, eventDate, customClassName, displayLanguageCode})
{
    var isContentAvailable = contentItem && contentItem[displayLanguageCode] && contentItem[displayLanguageCode].content; 
    var contentArray = isContentAvailable && contentItem[displayLanguageCode].type === "inline"
        ? ParseContentIntoParagraphs(contentItem[displayLanguageCode].content):["No content."];
    var dateDisplay = eventDate && eventDate !== '' && displayLanguageCode
    ? AppLabels.Labels.eventDate[displayLanguageCode] + ":" + new Date(eventDate).toDateString():'';


    console.log("Lang = "+displayLanguageCode+", isContentAvailable: " + isContentAvailable 
    + " contentItem[displayLanguageCode].content => " + contentItem[displayLanguageCode].type
    + " Content => " + JSON.stringify(contentItem[displayLanguageCode]));

    return (
        <span key={"contentContainer"+index} className={customClassName}>
            {/* <p key={"eventDate"+index}>{dateDisplay}</p> */}
            <span key="contentDiv">
            {
                isContentAvailable && contentItem[displayLanguageCode].type === "inline"
                ? contentArray.map((item, i)=> { return <p key={"contentitem"+i}>{item}</p>;})
                : isContentAvailable && contentItem[displayLanguageCode].type === "relativePathUrl"
                    ? <ContentViewerDynamic
                        contentUrl={contentItem[displayLanguageCode].content}
                        index ={index}
                        eventDate={eventDate}
                        displayLanguageCode={displayLanguageCode}
                        customClassName=""></ContentViewerDynamic>
                    :<p>Unsupported content type</p>   
            }
            </span>
        </span>
        );
}


export default ContentViewer;