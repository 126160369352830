import React from "react";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import {AppLabels as CultureInfo} from '../common/CultureInfo'
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import PlaceIcon from '@mui/icons-material/Place';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Link } from "@mui/material";
import IsSmallScreen from "../common/AppUtils";

export default function Contact({displayLanguageCode}) {

	var waLink = 'https://wa.me/'+ CultureInfo.contact.whatsappPhone[displayLanguageCode];
	var isSmallSizeScreen = IsSmallScreen();
	var minElementHeight = isSmallSizeScreen? "10vh":"15vh";
	var maxElementWidth = isSmallSizeScreen? "50vh":"70vh";


	return (<>
		<Grid2 container 
			lg={12} md={12} sm={12} xs={12}
			component={"div"}>
			<Grid2 item 
				lg={12} md={12} sm={12} xs={12}>
				{CultureInfo.contactPageTitle[displayLanguageCode]}
			</Grid2>
		</Grid2>
			
		<Box sx={{padding:"10px"}}>
			<Grid2 container lg={12} md={12} sm={12} xs={12} component={"div"}>
				<Grid2 item>
					<PersonOutlineIcon />
				</Grid2>
				<Grid2 item>
					<Card sx={{ minHeight: minElementHeight, maxWidth:maxElementWidth }}>
						<CardContent color="text.primary">
							<Typography sx={{ fontSize: 14 }} gutterBottom>
								{CultureInfo.contact.name[displayLanguageCode]},
							</Typography>
							<Typography component="div">
								{CultureInfo.contact.designation[displayLanguageCode]},
							</Typography>
							<Typography component="div">
								{CultureInfo.contact.institution[displayLanguageCode]}.
							</Typography>
						</CardContent>
					</Card>
				</Grid2>
			</Grid2>
		</Box>

		<Box sx={{padding:"10px"}}>
			<Grid2 container lg={12} md={12} sm={12} xs={12} component={"div"}>
				<Grid2 item>
					<PhoneIcon />
				</Grid2>
				<Grid2 item>
					<Card sx={{ minHeight: minElementHeight , maxWidth:maxElementWidth}}>
						<CardContent color="text.primary">
							<Typography sx={{ fontSize: 14 }} gutterBottom>
								<Link underline="hover" href={"tel:"+ CultureInfo.contact.phoneToDisplay[displayLanguageCode]}
									target="_blank"
									title="Click here to open the phone number in phone dialer">
									{CultureInfo.contact.phoneToDisplay[displayLanguageCode]}
								</Link >			
							</Typography>
							<Typography sx={{ fontSize: 14 }} gutterBottom>
								<Link underline="hover" href={"tel:"+ CultureInfo.contact.phone2ToDisplay[displayLanguageCode]}
									target="_blank"
									title="Click here to open the phone number in phone dialer">
									{CultureInfo.contact.phone2ToDisplay[displayLanguageCode]}
								</Link>			
							</Typography>
						</CardContent>
					</Card>
				</Grid2>
			</Grid2>
		</Box>

		<Box sx={{padding:"10px"}}>
			<Grid2 container lg={12} md={12} sm={12} xs={12} component={"div"}>
				<Grid2 item>
					<WhatsAppIcon />
				</Grid2>
				<Grid2 item>
				<Card sx={{ minHeight: minElementHeight, maxWidth:maxElementWidth }}>
						<CardContent color="text.primary">
							<Typography sx={{ fontSize: 14 }} gutterBottom>
								<Link underline="hover" aria-label="Chat on WhatsApp" href={waLink} target="_blank">
									{CultureInfo.contact.whatsappPhone[displayLanguageCode]}
								</Link>
							</Typography>
						</CardContent>
					</Card>
				</Grid2>
			</Grid2>
		</Box>
		
		<Box sx={{padding:"10px"}}>
			<Grid2 container lg={12} md={12} sm={12} xs={12} component={"div"}>
				<Grid2 item>
					<EmailIcon />
				</Grid2>
				<Grid2 item>
					<Card sx={{ minHeight: minElementHeight, maxWidth:maxElementWidth }}>
						<CardContent color="text.primary">
							<Typography sx={{ fontSize: 14 }} gutterBottom>
								<Link underline="hover" aria-label="Click this link to email us" 
									href={"mailto:"+CultureInfo.contact.emailAddress[displayLanguageCode]}
									target="_blank">
									{CultureInfo.contact.emailAddress[displayLanguageCode]}	
								</Link>
							</Typography>
							<Typography sx={{ fontSize: 14 }} gutterBottom>
								<Link underline="hover" aria-label="Click this link to email us" 
									href={"mailto:"+CultureInfo.contact.emailAddress2[displayLanguageCode]}
									target="_blank">
									{CultureInfo.contact.emailAddress2[displayLanguageCode]}	
								</Link>
							</Typography>
						</CardContent>
					</Card>
				</Grid2>
			</Grid2>
		</Box>		

		<Box sx={{padding:"10px"}}>
			<Grid2 container lg={12} md={12} sm={12} xs={12} component={"div"}>
				<Grid2 item>
					<PlaceIcon />
				</Grid2>
				<Grid2 item>
					<Card sx={{ minHeight: minElementHeight, maxWidth:maxElementWidth }}>
						<CardContent color="text.primary">
							<Typography sx={{ fontSize: 14 }} gutterBottom>
							<Link underline="hover" aria-label="Click this link to email us" 
									href={"https://www.google.com/maps/search/?api=1&query="+CultureInfo.contact.address[displayLanguageCode]}
									target="_blank">
									{CultureInfo.contact.address[displayLanguageCode]}	
								</Link>
							</Typography>
						</CardContent>
					</Card>
				</Grid2>
			</Grid2>
		</Box>
</>);
}

