import React, { useState } from "react";
import { IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { NestedMenuItem } from "mui-nested-menu";
//import MenuIcon from '@material-ui/icons/Menu';
//import NestedMenuItem from "material-ui-nested-menu-item";

import { BrowserRouter as Router, Routes, Route, Switch, BrowserRouter, Link, useParams, useNavigate} from 'react-router-dom';
import {MenuLabels} from '../common/CultureInfo'
import {AppLabels} from '../common/CultureInfo'


import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import MenuIcon from '@mui/icons-material/Menu';
import DoneIcon from '@mui/icons-material/Done';

export const NestedMenu = ({onToggleThemeCallback, displayLanguageCode, themeModeName, languages, OnLanguageChangeCallback}) => {
    const [menuPosition, setMenuPosition] = useState(null);
    const navigate = useNavigate();


  const handleRightClick = (event) => {
    if (menuPosition) {
      return;
    }
    event.preventDefault();
    setMenuPosition({
      top: event.pageY,
      left: event.pageX
    });
  };

  const handleItemClick = (event) => {
    setMenuPosition(null);
    navigate("/home", {replace: false});
  };

  const handleLanguageChange = (value) => {
    setMenuPosition(null);
    OnLanguageChangeCallback(value);
  };
  
  return (
    <div onContextMenu={handleRightClick} onClick={handleRightClick}>
    <MenuIcon/>
      <Menu
        open={!!menuPosition}
        onClose={() => setMenuPosition(null)}
        anchorReference="anchorPosition"
        anchorPosition={menuPosition}
      >
        <MenuItem  onClick={()=>{setMenuPosition(null);onToggleThemeCallback()}}>
            {MenuLabels.theme[displayLanguageCode]}
            <IconButton sx={{ ml: 1 , mr:1 }} color="inherit">
                {themeModeName === 'dark' ? <Brightness7Icon size="small" /> : <Brightness4Icon size="small"/>}
            </IconButton>
        </MenuItem>
        <NestedMenuItem
          label={AppLabels.Labels.language[displayLanguageCode]}
          parentMenuOpen={!!menuPosition}
          onClick={handleItemClick}
        >
            {languages.map((l, i)=>{
                    return <MenuItem key={"languageOption"+i} value={l.value} onClick={()=> handleLanguageChange(l.value)}>
                        {l.label}{displayLanguageCode === l.value?<DoneIcon/> :''}
                    </MenuItem>                        
                })}
        </NestedMenuItem>

        <NestedMenuItem
                label={AppLabels.Labels.language[displayLanguageCode]}
                parentMenuOpen={!!menuPosition}
                onClick={handleItemClick}
                >
          <MenuItem onClick={handleItemClick}>some menu</MenuItem>
          <MenuItem onClick={handleItemClick}>Sub-Button 2</MenuItem>
          <NestedMenuItem
            label="Sub-Button 3"
            parentMenuOpen={!!menuPosition}
            onClick={handleItemClick}
          >
        </NestedMenuItem>

        {/* <NestedMenuItem
          label="nested menu"
          parentMenuOpen={!!menuPosition}
          onClick={handleItemClick}
        >
            <MenuItem onClick={handleItemClick}>Sub-Sub-Button 1</MenuItem>
            <MenuItem onClick={handleItemClick}>Sub-Sub-Button 2</MenuItem>
          </NestedMenuItem> */}
        </NestedMenuItem>
        <MenuItem onClick={handleItemClick}>Button 4</MenuItem>
        <NestedMenuItem
          label="Button 5"
          parentMenuOpen={!!menuPosition}
          onClick={handleItemClick}
        >
          <MenuItem onClick={handleItemClick}>Sub-Button 1</MenuItem>
          <MenuItem onClick={handleItemClick}>Sub-Button 2</MenuItem>
        </NestedMenuItem>
      </Menu>
    </div>
  );
};

export default NestedMenu;
