import { useState, useEffect } from "react";
//import ReactDOM from 'react-dom';
//import { createRoot } from 'react-dom/client';
import {TraceLog, ParseContentIntoParagraphs, ReplaceAll} from '../common/AppUtils'
import CircularProgress from '@mui/material/CircularProgress';
import '../App.css';
import ReadMore from "./ReadMore"
import { AppLabels } from "../common/CultureInfo";
import { ElectricalServices } from "@mui/icons-material";


function ContentViewerDynamic({
    contentUrl,
    index,
    eventDate,
    displayLanguageCode="en",
    customClassName='',
    showReadMore=false,
    readMoreMinCharacters=150,
    customClassNameForContent=''
})
{
    const [isRequestInProgress, SetIsRequestInProgress] = useState(false);
    const [showLimitedContent, SetShowLimitedContent] = useState(showReadMore);
    const toggleReadMore = () => {
        SetShowLimitedContent(!showLimitedContent);
    };

    TraceLog("Received content url=> " + contentUrl);
    TraceLog("Received displayLanguageCode=> " + displayLanguageCode);


    const [currentContent, setCurrentContent] = useState(null);

    useEffect(() => {

        const getContentApiCall = async () => {
            try{
                SetIsRequestInProgress(true);

                fetch(contentUrl)
                .then(response => { var responseText = response.text(); console.log("Dynamic content from :" + contentUrl + " => " + JSON.stringify(responseText)); return responseText;})
                .then(response => { return ParseContentIntoParagraphs(response);})
                .then(response => {
                    setCurrentContent(response);
                    SetIsRequestInProgress(false);
                })
                .catch(error => {
                    SetIsRequestInProgress(false);
                    console.error(error);
                });
            }
            catch(e)
            {
                SetIsRequestInProgress(false);
                console.log("Error reading the dynamic content" + e);
            }
        }
        getContentApiCall();

        },[contentUrl]);



    if(!displayLanguageCode)
        displayLanguageCode = 'en';


    const cssClassNameForLanguage = displayLanguageCode + (customClassName?' ' + customClassName:''); 
    var charCounter = 0;
    var charsToShow = 0;
    return (<>
        {
            (!showReadMore || !showLimitedContent) && currentContent &&            
                <span className={cssClassNameForLanguage} key={"dynamicContentContainer"+ displayLanguageCode +index}>
                        {currentContent.map((item, i)=> { 
                            if(item && item.startsWith('"'))
                            {
                                var removedQuote = ReplaceAll('"', '', item);
                                return <blockquote>
                                        <p className={customClassNameForContent} key={"dynamicContentitem"+i}>{removedQuote}</p>
                                    </blockquote>
                            }
                            return <p className={customClassNameForContent} key={"dynamicContentitem"+i}>{item}</p>
                    })
                    }
                 
                    {
                        showReadMore &&
                        <span onClick={toggleReadMore} className="read-or-hide">
                            {showLimitedContent ? AppLabels.readMoreLabel[displayLanguageCode] : AppLabels.readLessLabel[displayLanguageCode]}
                        </span>
                    }
                </span>
        }
        {
            (showReadMore && showLimitedContent) && currentContent &&            
                <span className={cssClassNameForLanguage} key={"dynamicContentContainer"+ displayLanguageCode +index}>
                        {currentContent.map((item, i)=> { 

                            if(charCounter > readMoreMinCharacters)
                                return '';

                            if(item && item.startsWith('"'))
                            {
                                var removedQuote = ReplaceAll('"', '', item);
                                charCounter += removedQuote.length;
                                
                                return <blockquote>
                                        <p key={"dynamicContentitem"+i}>{removedQuote}</p>
                                    </blockquote>
                            }
                            
                            var charsToShow = readMoreMinCharacters - charCounter;
                            charCounter += item.length;
                            
                            if(item.length < charsToShow)
                            {
                                return <p className={customClassNameForContent} key={"dynamicContentitem"+i}>{item}</p>
                            }
                            if(charsToShow > 0)
                                return <p className={customClassNameForContent} key={"dynamicContentitem"+i}>{item.slice(0, charsToShow)}...
                                <span onClick={toggleReadMore} className="read-or-hide">
                                    {showLimitedContent ? AppLabels.readMoreLabel[displayLanguageCode] : AppLabels.readLessLabel[displayLanguageCode]}
                                </span>
                            </p>
                            
                            return '';
                    })
                    }
                    
                </span>
                
        }
        {
            isRequestInProgress && <CircularProgress color="success" />
        }
        </>);
}


export default ContentViewerDynamic;