import Dropdown from 'react-dropdown';
import { Typography } from '@mui/material';
import 'react-dropdown/style.css';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { AppLabels } from '../common/CultureInfo';
import * as CultureInfo from '../common/CultureInfo'
import { IconButton, Tooltip } from '@mui/material';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

function ThemeSelector({displayLanguageCode="en", themeModeName, OnToggleThemeCallback, parentKey='', customClassName='', hideLabel=false})
{
    var isDarkMode = themeModeName === 'dark';
    var currentThemeDisplayText = hideLabel?'': (isDarkMode? CultureInfo.AppLabels.Labels.currentThemeIsDarkText[displayLanguageCode]: 
    CultureInfo.AppLabels.Labels.currentThemeIsLightText[displayLanguageCode]);
    return (
        // <FormControl key={"themeSelectorForm"} sx={{ m: 1, minWidth: 5 }} size="small">            
            <span key={parentKey+"themeSelectorDisplayText"} onClick={()=>{OnToggleThemeCallback();}}>
                {currentThemeDisplayText}
                <IconButton key={parentKey+"themeSelectorIconButton"} sx={{ ml: 1 , mr:1 }} color="inherit" size='small'>
                    {isDarkMode ? <Brightness7Icon size="small" /> : <Brightness4Icon size="small"/>}
                </IconButton>                    
            </span>
        //</FormControl>
    );
    
}

export default ThemeSelector;