import React from "react";
import Marquee from 'react-double-marquee';
import IsSmallScreen, { TraceLog } from "../common/AppUtils";
import { Tooltip, Typography } from "@mui/material";

function AnnouncementViewer2({announcements, displayLanguageCode})
{
    TraceLog("input announcements = " + JSON.stringify(announcements));
    TraceLog("displayLanguageCode = " + displayLanguageCode);

    const filterAnnouncements=(inputAnnouncements)=>
    {
        var filteredResult = [];
        if(inputAnnouncements && inputAnnouncements.length)
        {
            for(var i in inputAnnouncements)
            {
                if(inputAnnouncements[i].displayText
                    && inputAnnouncements[i].displayText[displayLanguageCode])
                {
                    if(inputAnnouncements[i].type === "always" )
                    {
                        filteredResult.push(inputAnnouncements[i].displayText[displayLanguageCode]);
                    }
                    else if(inputAnnouncements[i].type === "timeBound"
                        && inputAnnouncements[i].endDate)
                    {
                        try
                        {
                            var isAnnouncementOver = new Date(inputAnnouncements[i].endDate) < new Date();
                            filteredResult.push(inputAnnouncements[i].displayText[displayLanguageCode]);                
                        }
                        catch(e){}
                    }
                }
            }
        }

        return filteredResult;
    }

    var filteredAnnouncements = filterAnnouncements(announcements);
    var isAnnouncementPresent = true || filteredAnnouncements.length>0;

    TraceLog("filtered announcements = " + JSON.stringify(filteredAnnouncements));
    TraceLog("announcements = " + isAnnouncementPresent);
    
    var allAnnouncements = ''.concat(filteredAnnouncements);
    var isSmallSizedScreen = IsSmallScreen();
    var scrollWidth = isSmallSizedScreen? "35vw":"50vw";
    return(
            isSmallSizedScreen?<></>:<div style={{display:"block", width:scrollWidth, overflowX:"hidden", whiteSpace:"nowrap"}}>
                    <Marquee 
                        key={"announcementViewer2"}
                        speed={0.01} 
                        delay={1000}
                        direction={"left"} 
                        children={"span"} 
                        scrollWhen={"always"}>
                            {allAnnouncements}
                    </Marquee>
            </div>
        )
}

export default AnnouncementViewer2; 